export default {
  core: {
    history: "Le cours",
    ok: "OK",
    cancel: "Avorter",
    read_more: "Afficher plus",
    close_modal: "Fermer"
  },
  coupon: {
    back: "Dos",
    cancel: "Avorter",
    redeem: "Continuer",
    input: {
      code: "Code de coupon"
    },
    modal: {
      default: {
        title: "Code de coupon",
        text: "Vous pouvez payer l’acompte de {deposit_value} avec un bon d'achat valide. Si la valeur du bon n'est pas suffisante, vous pouvez régler le montant restant avec un autre paiement."
      },
      insufficient: {
        title: "La valeur du bon n'est pas suffisante",
        text: "Le coupon a une valeur de {coupon_amount}. Vous pouvez toujours échanger le coupon et effectuer un paiement final de {deposit_balance}."
      },
      invalid: {
        title: "Bon d'achat non valable",
        text: "Malheureusement, le bon que vous avez saisi n'est pas valide. Veuillez utiliser un bon d'achat valide ou un autre mode de paiement."
      },
      remaining: {
        title: "Paiement du solde",
        item: "{payment_date} Le paiement de {payment_amount} a été effectué avec succès.",
        text: "Veuillez sélectionner un mode de paiement que vous souhaitez utiliser pour payer le solde de {deposit_balance}"
      }
    }
  },
  page: {
    gallery: {
      image: "Photos",
      video: "Vidéo",
      pano: "360°",
      plan: "Plan",
      gallery: "Galerie de photos",
      mute_audio: "Désactiver le son",
      unmute_audio: "Activer le son"
    },
    whatsapp: {
      scan_code: "Scanner le QR Code",
      contact_over: "Contact via WhatsApp App",
      whatsapp_web: "WhatsApp Web",
      whatsapp_web_contact: "Contact via l'application web"
    },
    mode: {
      configuration: {
        message: "Landingpage befindet sich in Konfigurationsmodus."
      },
      versioned: {
        message: "Version du {date}",
        message_default: "Ancienne version"
      }
    },
    success: {
      messageSuccessful: "Le message a bien été envoyé"
    },
    errors: {
      messageIsEmpty: "Ajouter un message, s'il vous plaît",
      no_hash: {
        title: "Une erreur est survenue",
        text: "Veuillez réessayer plus tard."
      },
      payment: {
        title: "Le paiement n'a pas pu être effectué",
        text: "Veuillez réessayer plus tard",
        hint: "Message d'erreur: ",
        btn_retry: "Réessayer"
      },
      checkin: {
        tech_issue: {
          title:
            "Désolé, le service d'enregistrement en ligne n'est pas disponible",
          text: "Veuillez nous contacter si vous souhaitez effectuer un enregistrement en ligne."
        }
      },
      book_after_arrival_date: {
        title: "L'offre  n'est plus disponible",
        text: "Désolé, l'offre que vous avez demandée n'est plus disponible. Veuillez nous contacter pour en recevoir une nouvelle offre."
      }
    },
    nav: {
      offer: "Offre",
      highlights: "Moments forts",
      info_services: "Infos & Services",
      about: "About",
      contact: "Contact",
      terms_of_use: "Terms of use",
      privacy_policy: "Privacy Policy"
    },
    content: {
      today_reception: "Aujourd'hui pour vous à la réception:"
    },
    highlights: {},
    footer: {
      partners: "Partnerbetriebe",
      contact: "Contactez-nous",
      menu: "Navigation",
      info: "Informations"
    },
    account: {
      vat: "TVA",
        cin: "CIN (Codice Identificativo Nazionale)"
    },
    placeholder: {
      whatsapp: "J'ai une question sur l'offre {offer}"
    }
  },
  entity: {
    best_offer: "Notre meilleure offre pour vous",
    details: "Details",
    insurance: {
      text: "Avec notre assurance annulation de voyage, vous êtes financièrement protégé si vous êtes empêché de voyager de manière inattendue.",
      ctabtn: "À L'ASSURANCE VOYAGE",
      title: "L'assurance voyage"
    },
    selected: "CHOISI",
    select: "Choisir",
    highlight: "Moments forts",
    highlights_header: "Moments forts",
    personal_info_header: "Données personnelles",
    personal_info: "Données personnelles",
    show_details: "Afficher les détails",
    hide_details: "Cacher les détails",
    variant: "Variante",
    variant_x: "Variante {x}",
    nights_x: "1 Nuit|{x} Nuits",
    room_x: "Chambre {x}",
    arrival: "Arrivée",
    arrival_daparture: "Arrivée/Départ",
    persons_treatment: "Personnes/Alimentation",
    room_category: "Catégorie de chambre",
    departure: "Départ",
    offer: "Offre",
    entity_of: " {n} de {count}",
    deposit_needed: "Versement anticipé de {amount} nécessaire",
    no_deposit_needed: "Aucun acompte n'est nécessaire",
    valid_till: "Offre valable jusqu'au:",
    valid_till_expired:
      "Désolé, cette offre n'est plus valable, veuillez nous contacter si vous souhaitez en recevoir une autre offre.",
    cancel_policy: {
      title: "Les conditions d'annulation",
      show: "Afficher les conditions d'annulation",
      free_cancellation: "Annulable sans frais",
      free_cancellation_until: "Annulable sans frais dans un délai de "
    },
    mealplans: {
      HB: "Demi-pension",
      WS: "-",
      BB: "Bed&Breakfast",
      FB: "Pension complète",
      AI: "All Inclusive",
      RO: "Nuitée seulement"
    },
    room: {
      rooms: "Hébergement",
      persons: "1 Personne|{count} Personnes",
      child_pl: "1 Enfant|{count} Enfants",
      adult_pl: "1 Adulte|{count} Adultes",
      traveler: "Voyageurs",
      treatment: "Repas",
      period: "Période de voyage",
      info: "Informations",
      description: "Description",
      room_change_on: "Changement de chambre le"
    },
    treatment: {
      close: "Fermer",
      booked_spa_header: "Services SPA réservés",
      date: "Date",
      time: "Temps",
      time_definition: "{time}",
      period: "Durée",
      show_details: "Montrer les détails",
      time_slug: "min",
      package: {
        applied_spa: "Services",
        description: "Description",
        total_amount: "Prix total"
      },
      item: {
        description: "Description",
        title: "Application",
        quantity: "Nombre",
        price: "Prix",
        time: "Durée",
        guest_name: "Invité",
        date: "Date",
        date_time: "Temps"
      }
    },
    price: {
      description: "Description",
      description_short: "Descr.",
      quantity: "Numéro",
      discount: "Rabais",
      amount: "Somme",
      taxes_included: "Taxe de séjour incluse",
      show_price_details:
        "Afficher les détails des prix et les conditions d'annulation",
      show_price_details_short: "Afficher les détails des prix",
      hide_price_details: "Prix Masquer les détails",
      deposit_balance: "L’acompte restante",
      price: "Prix",
      total_price: "Prix total",
      deposit: "Dépôt",
      deposit_amount: "Dépôt à payer",
      balance: "Montant restant"
    },
    payment: {
      select_method: "Veuillez sélectionner un mode de paiement",
      payment_success:
        "Le paiement de {amount} a été effectué avec succès par {gateway_name} le {payment_created_data} à {payment_created_time}.",
      authorized_bank_transfer_payment:
        "{gateway_name} a été sélectionné comme méthode de paiement pour le montant de {amount}.",
      success_bank_transfer_payment:
        "Le transfert de {amount} a été effectué avec succès le {payment_changed_data} à {payment_changed_time} au moyen de la {gateway_name}.",
      default_payment_success:
        "Le paiement de {amount} a été effectué avec succès le {payment_created_data}.",
      default_payment_authorized: "Le paiement de {amount} a été enregistré.",
      no_payment_methods:
        "Malheureusement, cette offre ne peut être réservée car aucun mode de paiement n'a été défini. Veuillez contacter l'hôtel pour plus d'informations.",
      preauthorized_payment:
        "Le paiement de {amount} a été autorisé avec succès le {payment_created_data} à {payment_created_time} au moyen de la {gateway_name}.",
      methods: {
        credit_card: "Kreditkarte",
        credit_card_hint:
          "Kreditkarte: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        wire_transfere: "Überweisung",
        wire_transfere_hint:
          "Überweisung: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        paypal: "PayPal",
        paypal_hint:
          "PayPal: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur."
      },
      secure_checkout: "Départ sécurisé",
      secure_checkout_hint:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat condimentum."
    },
    request: {
      thank_you: {
        header: "Merci pour votre réservation",
        text: "Vos données ont été soumises avec succès et nous les traiterons dès que possible. Si vous avez d'autres questions, n'hésitez pas à nous contacter.",
        button: "Fermer"
      }
    },
    buttons: {
      book: "Réserver",
      details: "Details",
      coupon_apply: "Utiliser",
      reserve: "Réservez sans engagement"
    },
    expiration_time: "à {time} HEC"
  },
  personal_details: "Données personnelles",
  checkin: {
    room_x_of: "Hébergement",
    next: "Continuer",
    select: {
      country: "Choisissez un pays",
      date: "Choisissez la date",
      time: "Choisissez l'heure"
    },
    allocate_room: "Attribuer des chambres",
    thank_you: {
      title: "L'enregistrement en ligne a été effectué avec succès!",
      text: "Vos données ont été soumises avec succès et nous les traiterons dès que possible. Si vous avez d'autres questions, n'hésitez pas à nous contacter."
    },
    title: "Enregistrement en ligne",
    guest_1: "Invité principal",
    guest_2: "Invité supplémentaire",
    choose_room: "Veuillez choisir une chambre"
  },
  promocode: {
    apply: "Utiliser",
    remove: "Supprimer",
    label: "Code promotionnel",
    added: "Le code promotionnel a été appliqué",
    not_valid: "Le code promotionnel a été validé",
    deleted: "Le code promotionnel a été supprimé"
  },
  form: {
    promocode: "Code promotionnel",
    promocode_apply: "Utiliser",
    errors: {
      selectPayMethod: "Veuillez choisir le mode de paiement"
    },
    error_occured:
      "Une erreur s'est produite, veuillez vérifier votre saisie et réessayer.",
    payment: {
      error: {
        title: "Une erreur s'est produite",
        text: "Veuillez réessayer plus tard"
      }
    },
    doctypes: {
      ID_CARD: "Carte d'identité",
      DRIVERS_LICENSE: "Permis de conduire.",
      PASSPORT: "Passeport"
    },
    labels: {
      additional_info: "Informations complémentaires",
      document_info: "Données du badge",
      birth_info: "Dates de naissance",
      address_info: "Adresse",
      contact_info: "Nom et contact",
      assign_room: "attribuer des chambres",
      other_info: "Autres"
    },
    region: "Province",
    upload: "Téléchargement",
    country_iso: "Pays",
    mobilephone: "Mobile",
    birth_date: "Date de naissance",
    birth_place: "Lieu de naissance",
    birth_municipality: "Lieu de naissance/paroisse",
    birth_country_iso: "Pays de naissance",
    birth_region: "Province",
    fiscal_code: "Numéro d'identification fiscale",
    nationality_iso: "Nationalité",
    document_type: "Type de document",
    document_number: "document n°",
    document_valid_from: "Date d'émission",
    document_valid_to: "Valable jusqu'au",
    document_issuing_country_iso: "Pays d’émission",
    document_issuing_city: "Autorité émettrice",
    arrival: "Arrivée",
    checkin_time: "Heure d'arrivée",
    license_plate: "Plaque d'immatriculation",
    driver_name: "Nom du conducteur/de la conductrice",
    car_brand: "Marque de voiture",
    notes: "Autres souhaits",
    terms_short: "Accepter la politique de confidentialité",
    action_send: "Soumettre",
    gender_please_select: "Veuillez choisir le sexe",
    back: "Dos",
    close: "Fermer",
    select_paymethod: "Sélectionnez le mode de paiement",
    checkout: "Départ",
    salutation: "Salutation",
    title_options: {
      m: "Monsieur",
      f: "Madame"
    },
    name: "Nom",
    firstname: "Prénom",
    lastname: "Nom de famille",
    street: "Rue",
    zip: "Code postal",
    city: "Ville",
    municipality: "Communauté",
    country: "Pays",
    email: "E-mail",
    email_hint: "Lorem ipsum dolor sit amet consectetur.",
    phone: "Téléphone",
    cellphone: "Mobile",
    phone_hint: "Lorem ipsum dolor sit amet consectetur.",
    contact: "Adresse",
    hint_booking: "{link_booking} Loren ipsum sit dolor",
    //your_request: 'Votre demande',
    easy_reservation: "Réservez sans engagement",
    terms:
      "J'accepte par la présente la politique de confidentialité et les conditions générales.",
    checkin_terms:
      "J'accepte par la présente la [Politique de confidentialité].",
    newsletter:
      "Je souhaite recevoir des communications régulières sur les offres et les nouveautés.",
    check_terms: "Veuillez accepter les conditions générales"
  },
  dict: {
    for: "pour"
  },
  booking: {
    default: {
      title: "Merci pour votre demande!",
      text: "Votre demande a été soumise avec succès et nous la traiterons dès que possible. Pour toute autre question, nous sommes à votre disposition."
    },
    offer: {
      title: "Merci pour votre demande de réservation!",
      text: "Votre demande de réservation a été soumise avec succès et nous confirmerons la réservation dès que possible. N'hésitez pas à nous contacter si vous avez d'autres questions."
    },
    order: {
      title: "Merci pour votre demande de réservation!",
      text: "Votre réservation a été soumise avec succès et nous la confirmerons dès que possible. Si vous avez d'autres questions, n'hésitez pas à nous contacter."
    }
  },
  chat_window: {
    new_messages: "Vous avez des messages non lus !",
    message_empty: "Pas de message",
    conversation_started: "Conversation commencée le:",
    type_message: "Saisir un message",
    search: "Chercher",
    previewMode:
      "En mode aperçu, il n'est pas possible d'envoyer des messages de chat."
  },
  checkin_error_message:
    "Attention, cette campagne n'est pas attribuée à un invité. Il faut d'abord ajouter un invité pour que le formulaire d'enregistrement en ligne puisse être rempli.",
  checkout: {
    price_table: "Tableau de prix"
  },
  chat_email_from: "de",
  upselling: {
    category: {
      category_title: "Catégorie",
      without_category: "Prestations complémentaires sans catégorie"
    },
    service: {
      service_title: "Prestations supplémentaires",
      add_service: "Au choix",
      selected_service: "sélectionné",
      change_service: "Modifier",
      add_to_cart_service: "Ajouter",
      calc_modes: {
        MODE_PER_DAY: "par nuit",
        MODE_PER_PERSON: "par personne",
        MODE_PER_DAY_AND_PERSON: "par nuit et par personne",
        MODE_PER_PRODUCT_UNIT: "par unité d'hébergement",
        MODE_PER_LIVING_UNIT_AND_DAY: "par unité d'hébergement et par nuit",
        MODE_PER_ITEM: "forfait"
      },
      calc_info:
        'Le prix affiché est le montant total du mode de calcul "{calc_mode}" de cette prestation.',
      preselected_service_hotel: "Réservé {n}x par l'hôtel",
      preselected_service_customer: "Réservés {n}x par le client",
      show_more_services: "Afficher tous les prestations",
      hide_services: "Cacher toutes les prestations"
    },
    service_price_table: {
      service: "Prestation",
      quantity: "Nombre",
      price: "Prix",
      amount: "Somme",
      description: "Description"
    }
  },
  availability: {
    loading_message: "La disponibilité est vérifiée, cela peut prendre quelques minutes.",
    request_offer_btn: "Envoyer une nouvelle demande d'offre",
    message: 'Cette offre n\'est plus disponible. Je souhaite recevoir une nouvelle offre!',
    request_error_message: "La demande n'a pas pu être envoyée. Veuillez réessayer plus tard.",
    request_success_message: "La demande a été envoyée avec succès.",
    fallback_unavailable_title: "Cette offre n'est plus disponible!",
    fallback_unavailable_text: "Nous avons le regret de vous informer que l'offre que vous avez demandée n'est actuellement plus disponible à la réservation. Pour effectuer une nouvelle demande, veuillez cliquer sur le bouton ci-dessous. Notre équipe s'occupera de votre demande dans les plus brefs délais. C'est un plaisir pour nous de vous aider à trouver le séjour parfait!"
  }
};
