<template>
  <div>
    <b-alert class="expired-alert" show>
      <b-icon icon="info-circle"></b-icon>
      <div
        v-html="
          placeholder(
            '%label.offer.expired.text%',
            $t('entity.valid_till_expired')
          )
        "
        class="mx-3"
      />
      <b-btn @click="$bvModal.show('expired-dialog')">
        {{ placeholder("%label.offer.expired.ctabtn%") }}
      </b-btn>
    </b-alert>
    <b-modal
      id="expired-dialog"
      size="lg"
      class="pl-0"
      hide-header
      centered
    >
      <div class="closeModal">
        <button @click="$bvModal.hide('expired-dialog')">
          {{ $t("entity.treatment.close") }}
          <span class="closeIcon"><b-icon icon="x"></b-icon></span>
        </button>
      </div>
      <div class="d-flex justify-content-center flex-column align-items-end">
        <b-form-textarea
          v-model="message"
          class="w-100 my-3"
          :state="messageContainsError"
          @input="validateMessage()"
        ></b-form-textarea>
        <b-btn @click="sendMessage()" class="expired-dialog-button">
          {{ placeholder("%label.offer.expired.ctabtn%") }}
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import utilMixin from "../mixins/util";

export default {
  mixins: [lpMixins, utilMixin],
  props: ["page", "selectedVariantRef"],
  data() {
    return {
      message: this.placeholder("%label.offer.expired.message%") || "",
      dialogOpen: false,
      messageContainsError: null
    };
  },
  methods: {
    async sendMessage() {
      if (this.message) {
        const body = {
          message: this.message,
          ref: this.selectedVariantRef,
          scope: "offer_expired"
        };
        try {
          await this.axios.post(
            this.backendURL + this.hash + "/notify",
            body
          );
          this.message = "";
          this.successMessage(this.$t("page.success.messageSuccessful"));
        } catch (e) {
          this.errorMessage(
            `<strong>${this.$t(
              "form.payment.error.title"
            )}</strong><br />${this.$t("form.payment.error.text")}`
          );
        }
      } else {
        this.errorMessage(this.$t("page.errors.messageIsEmpty"));
      }
    },
    validateMessage() {
      this.messageContainsError = this.message === "" ? false : null;
    }
  }
};
</script>