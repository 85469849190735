<template>
  <div>
    <button
      type="button"
      :disabled="btnDisabled"
      :class="{
        'custom-italic': page.design_data.style?.cta?.italic,
        'custom-bold': page.design_data.style?.cta?.bold,
        'd-none': page.config?.disable_checkin === 1
      }"
      class="btn action-book btn-success btn-lg"
      @click.prevent="book()"
    >
      <template>
        <span
          v-if="!isCheckingAvailability"
          v-html="
            placeholder('%label.offer.ctabtn%', $t('entity.buttons.book'))
          "
        ></span>
        <b-spinner
          v-else
          variant="light"
          class="ml-2"
          style="width: 20px; height: 20px"
        ></b-spinner>
      </template>
    </button>

    <b-modal
      id="check-availability"
      v-model="isCheckingAvailability"
      centered
      no-stacking
      hide-header
      no-close-on-backdrop
    >
      <div class="text-center py-4">
        <b-spinner style="width: 40px; height: 40px" class="mt-2"></b-spinner>
      </div>
      <p class="mt-3 mb-4 pb-2 px-4">
        {{ $t("availability.loading_message") }}
      </p>
    </b-modal>

    <b-modal v-model="isNotAvailableDialog" centered no-stacking size="lg">
      <template #modal-title>
        {{ placeholder("%label.offer.unavailable%", $t("availability.fallback_unavailable_title")) }}
      </template>
      <p class="text-unavailable-offer">{{ placeholder("%label.offer.unavailable.text%", $t("availability.fallback_unavailable_text")) }}</p>

      <b-button variant="primary" class="mt-4" @click="openNewRequestDialog()">
        {{ $t("availability.request_offer_btn") }}
      </b-button>

      <div class="footer d-flex justify-content-between mt-4 pt-3">
        <div>
          <p class="mb-0 font-weight-bold">
            {{ page.account.name }}
          </p>
          <p v-if="page.account.address.data" class="mb-0">
            {{ address }}
          </p>
          <p v-if="page.account.contact.data?.phone" class="mb-0">
            {{ `T ${page.account.contact.data.phone}` }}
          </p>
          <p v-if="page.account.contact.data" class="mb-0">
            {{ page.account.contact.data.email }}
            <span v-if="page.account.contact.data.website">
              {{ `| ${page.account.contact.data.website}` }}
            </span>
          </p>
        </div>
        <div class="d-flex align-items-center">
          <b-img
            v-if="page.account.logo.data"
            :src="page.account.logo.data.url + '?d=100x100'"
            class="img-fluid"
          />
        </div>
      </div>
    </b-modal>

    <b-modal
      id="send-message"
      v-model="sendNewRequestDialog"
      centered
      no-stacking
      hide-header
    >
      <div class="closeButton">
        <button @click="sendNewRequestDialog = false">
          <span class="closeIcon"><b-icon icon="x"></b-icon></span>
        </button>
      </div>
      <b-form-textarea v-model="message" />

      <div class="text-right">
        <b-button
          variant="primary"
          class="mt-4"
          :disabled="!message || isSendingMessage"
          @click="sendMessage()"
        >
          {{ $t("availability.request_offer_btn") }}
          <b-spinner
            v-if="isSendingMessage"
            variant="light"
            class="ml-2"
            style="width: 18px; height: 18px"
          ></b-spinner>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script setup>
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useBookingMethods } from "@/composables/useBookingMethods";
import { shallowRef, getCurrentInstance, computed } from "vue";
import axios from "axios";
import { captureException } from "@sentry/vue";

const { placeholder, getCountry, scopes } = useCheckoutData();
const { bookAction } = useBookingMethods();
const { proxy } = getCurrentInstance();

const props = defineProps({
  btnDisabled: { type: Boolean, required: true },
  page: { type: Object, required: true },
  selectedVariantRef: { type: String, required: true },
  lpUrl: { type: String, required: true }
});

const isNotAvailableDialog = shallowRef(false);
const sendNewRequestDialog = shallowRef(false);
const isCheckingAvailability = shallowRef(false);
const message = shallowRef(proxy.$t("availability.message"));
const isSendingMessage = shallowRef(false);

const address = computed(() => {
  return `${props.page.account.address.data.street} |
  ${props.page.account.address.data.city_zip}
  ${props.page.account.address.data.city_name} -
  ${getCountry(props.page.account.address.data.country_alpha3)}`;
});

function book() {
  const isOffer = scopes.offer.includes(props.page.entity.scope);
  const isOption = scopes.option.includes(props.page.entity.scope);
  if ((isOffer || isOption) && props.page?.availability_request) {
    checkAvailability();
  } else {
    bookAction(false);
  }
}

async function checkAvailability() {
  try {
    isCheckingAvailability.value = true;
    const response = await axios.post(`${props.lpUrl}/availability`, {
      variant_ref: props.selectedVariantRef
    });
    const availability = response.data?.data;
    if (availability.available) {
      bookAction(false);
    } else {
      isNotAvailableDialog.value = true;
    }
  } catch (e) {
    captureException(e);
  } finally {
    isCheckingAvailability.value = false;
  }
}

function openNewRequestDialog() {
  sendNewRequestDialog.value = true;
  isNotAvailableDialog.value = false;
}

async function sendMessage() {
  try {
    isSendingMessage.value = true;
    const payload = {
      message: message.value,
      ref: props.selectedVariantRef,
      scope: "offer_unavailable"
    };
    await axios.post(`${props.lpUrl}/notify`, payload);
    proxy.$toast.success(proxy.$t("availability.request_success_message"), {
      position: "bottom",
      duration: 5000
    });
  } catch (e) {
    captureException(e);
    proxy.$toast.error(proxy.$t("availability.request_error_message"), {
      position: "bottom",
      duration: 5000
    });
  } finally {
    isSendingMessage.value = false;
    sendNewRequestDialog.value = false;
    message.value = proxy.$t("availability.message");
  }
}
</script>
<style scoped>
:deep(.modal-header) {
  border-bottom: none;
}

:deep(#check-availability .modal-dialog) {
  min-width: 550px;
}

:deep(#send-message .modal-dialog) {
  min-width: 600px;
}

.footer p {
  font-size: 0.9rem;
}

.footer img {
  width: 150px;
}

.text-unavailable-offer {
  white-space: pre-line;
}
</style>