<template>
  <div>
    <DebugBar
      :page="page"
      :lpLocale="lpLocale"
      :selectedVariant="selectedVariant"
      :resevation_process="reservation_process"
    />
    <div v-if="mode === 'configuration'" id="configuration-mode-wrapper">
      <div id="configuration-mode">
        {{ $t("page.mode.configuration.message") }}
      </div>
    </div>
    <div v-if="hash_type === 'versioned'" id="version-mode">
      <div class="message-wrapper">
        {{
          ("version_data" in page &&
            page.version_data &&
            page.version_data.data &&
            page.version_data.data.created_at &&
            $t("page.mode.versioned.message", {
              date: $moment(page.version_data.data.created_at).format("L LT")
            })) ||
          $t("page.mode.versioned.message_default")
        }}
      </div>
    </div>
    <loading
      v-if="!isCheckout"
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <RedirectedLoading />
    <div v-if="page.ready">
      <print-version :page="page" :selectedVariant="selectedVariant" :lpLocale="lpLocale"/>
      <div id="app" class="ynv-app">
        <navigation
          :page="page"
          v-if="navigationPositionComputed == 'top'"
          :history="{
            state: page.lp_show_versions || false,
            data: (page.versions && page.versions.data) || []
          }"
          :navigationItems="navigation.mainNav"
          :customStyle="page.design_data.style"
          :editor="page.entity.editor"
          :string-on-reception="
            placeholder(
              '%label.receptionist.title%',
              $t('page.content.today_reception')
            )
          "
          :config="page.design_data.config"
          :account="page.account"
          :entity="page.entity"
          @startConversation="
            startConversation(
              $event,
              placeholder(
                '%label.whatsapp.message.placeholder%',
                $t('page.placeholder.whatsapp', {
                  offer: page.entity.slug
                }),
                false,
                {
                  offer: page.entity.slug
                }
              ),
              page.account.contact.data
            )
          "
        />
        <Header
          v-if="page.sections.header && page.sections.header.overwrite != 3"
          :style-data="page.design_data.style || {}"
          :header-data="page.elements.header || {}"
          :config-data="page.design_data.config"
          :override="page.sections.header.overwrite"
          :account-data="page.account"
        />
        <navigation
          :page="page"
          v-if="navigationPositionComputed == 'bottom'"
          :history="{
            state: page.lp_show_versions || false,
            data: (page.versions && page.versions.data) || []
          }"
          :navigationItems="navigation.mainNav"
          :customStyle="page.design_data.style"
          :editor="page.entity.editor"
          :string-on-reception="
            placeholder(
              '%label.receptionist.title%',
              $t('page.content.today_reception')
            )
          "
          :config="page.design_data.config"
          :account="page.account"
          :entity="page.entity"
          @startConversation="
            startConversation(
              $event,
              placeholder(
                '%label.whatsapp.message.placeholder%',
                $t('page.placeholder.whatsapp', {
                  offer: page.entity.slug
                }),
                false,
                {
                  offer: page.entity.slug
                }
              ),
              page.account.contact.data
            )
          "
        />
        <expired-alert
          v-if="
            page.entity.expires_at &&
            this.scopes.expired.includes(page.entity.scope) &&
            $moment(new Date()).diff($moment(page.entity.expires_at).format()) >
              0
          "
          :page="page"
          :selectedVariantRef="selectedVariant.ref"
        />
        <div id="containerOrder">
          <b-container
            v-if="page.sections.content && page.sections.content.overwrite != 3"
            class="ynv-container"
            id="content"
          >
            <div
              class="custom-section-default"
              v-if="page.sections.content.overwrite != 2"
            >
              <b-row class="justify-content-center">
                <b-col cols="12" md="6">
                  <template
                    v-if="
                      (is('option') || is('offer')) &&
                      is('confirmed_byguest') &&
                      (confirmation_config == 'top' ||
                        confirmation_config == 'replace') &&
                      confirmation_config != 'none'
                    "
                  >
                    <div :class="{ 'mb-5': confirmation_config != 'replace' }">
                      <h1
                        v-html="
                          placeholder(
                            '%label.offer.confirm.title%',
                            $t(
                              'booking.' +
                                ([
                                  'crm.campaign.order.unconfirmed',
                                  'crm.campaign.offer.unconfirmed'
                                ].indexOf(page.entity.scope) >= 0
                                  ? is('offer')
                                    ? 'offer'
                                    : 'order'
                                  : 'default') +
                                '.title'
                            ),
                            true
                          )
                        "
                        :class="{
                          'custom-italic':
                            'intro' in page.design_data.style &&
                            'intro_italic' in page.design_data.style.intro &&
                            page.design_data.style.intro.intro_italic,
                          'custom-bold':
                            'intro' in page.design_data.style &&
                            'intro_bold' in page.design_data.style.intro &&
                            page.design_data.style.intro.intro_bold
                        }"
                        class="intro-title"
                      />
                      <p
                        v-html="
                          placeholder(
                            '%label.offer.confirm.text%',
                            $t(
                              'booking.' +
                                ([
                                  'crm.campaign.order.unconfirmed',
                                  'crm.campaign.offer.unconfirmed'
                                ].indexOf(page.entity.scope) >= 0
                                  ? is('offer')
                                    ? 'offer'
                                    : 'order'
                                  : 'default') +
                                '.text'
                            ),
                            true
                          )
                        "
                        :class="{
                          'custom-italic':
                            'intro' in page.design_data.style &&
                            'text_italic' in page.design_data.style.intro &&
                            page.design_data.style.intro.text_italic,
                          'custom-bold':
                            'intro' in page.design_data.style &&
                            'text_bold' in page.design_data.style.intro &&
                            page.design_data.style.intro.text_bold
                        }"
                        class="intro-text"
                      ></p>
                    </div>
                  </template>
                  <template
                    v-if="
                      confirmation_config != 'replace' ||
                      (confirmation_config === 'replace' &&
                        !is('confirmed_byguest'))
                    "
                  >
                    <h1
                      v-if="
                        page.elements.content.title &&
                        page.elements.content.title.length > 0
                      "
                      :class="{
                        'custom-italic':
                          'intro' in page.design_data.style &&
                          'intro_italic' in page.design_data.style.intro &&
                          page.design_data.style.intro.intro_italic,
                        'custom-bold':
                          'intro' in page.design_data.style &&
                          'intro_bold' in page.design_data.style.intro &&
                          page.design_data.style.intro.intro_bold
                      }"
                      class="intro-title"
                    >
                      {{
                        getVal(
                          page.elements.content.title[0].content_data.data
                            .content
                        )
                      }}
                    </h1>
                    <p
                      v-if="
                        page.elements.content.message &&
                        page.elements.content.message.length > 0
                      "
                      v-html="
                        getVal(
                          page.elements.content.message[0].content_data.data
                            .content
                        )
                      "
                      :class="{
                        'custom-italic':
                          'intro' in page.design_data.style &&
                          'text_italic' in page.design_data.style.intro &&
                          page.design_data.style.intro.text_italic,
                        'custom-bold':
                          'intro' in page.design_data.style &&
                          'text_bold' in page.design_data.style.intro &&
                          page.design_data.style.intro.text_bold
                      }"
                      class="intro-text"
                    ></p>
                  </template>
                  <template
                    v-if="
                      (is('option') || is('offer')) &&
                      is('confirmed_byguest') &&
                      confirmation_config == 'bottom'
                    "
                  >
                    <div class="mt-5">
                      <h1
                        v-html="
                          placeholder(
                            '%label.offer.confirm.title%',
                            $t(
                              'booking.' +
                                ([
                                  'crm.campaign.order.unconfirmed',
                                  'crm.campaign.offer.unconfirmed'
                                ].indexOf(page.entity.scope) >= 0
                                  ? is('offer')
                                    ? 'offer'
                                    : 'order'
                                  : 'default') +
                                '.title'
                            ),
                            true
                          )
                        "
                        class="intro-title"
                      />
                      <p
                        v-html="
                          placeholder(
                            '%label.offer.confirm.text%',
                            $t(
                              'booking.' +
                                ([
                                  'crm.campaign.order.unconfirmed',
                                  'crm.campaign.offer.unconfirmed'
                                ].indexOf(page.entity.scope) >= 0
                                  ? is('offer')
                                    ? 'offer'
                                    : 'order'
                                  : 'default') +
                                '.text'
                            ),
                            true
                          )
                        "
                        class="intro-text"
                      />
                    </div>
                  </template>
                </b-col>
              </b-row>
              <b-row class="content-highlight-spacing">
                <b-col
                  v-if="
                    ('block' in page.elements.content &&
                      page.elements.content.block.length > 0) ||
                    ('card' in page.elements.content &&
                      page.elements.content.card.length > 0)
                  "
                  cols="12"
                  class="highlights p-0"
                >
                  <highlights
                    :title="''"
                    :design-data="page.design_data"
                    :config="page.sections.content"
                    :content="page.elements.content_raw"
                    :highlightContentLoaded="blockContentLoaded"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-0">
                <b-col v-if="editorPositionComputed == 'top'" cols="12">
                  <div
                    class="d-flex justify-content-center"
                    style="margin: 80px 0"
                  >
                    <editor
                      :page="page"
                      v-if="page.entity.editor.data"
                      :isSidebar="false"
                      :editor-data="page.entity.editor.data"
                      :account-data="page.account"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
            <div
              class="custom-section-default"
              v-else-if="
                page.sections.content.overwrite == 2 &&
                'block' in page.elements.content
              "
            >
              <custom-element
                :page="page"
                :content="page.elements.content.block[0]"
              />
            </div>
          </b-container>
          <b-container
            data-order="2"
            id="products"
            :style="{ order: order === 6 ? 6 : '2' }"
            :class="{ 'ynv-container': page.entity.variants.data.length > 1 }"
            v-if="!is('declined') && order !== 0"
          >
            <variant-list
              :variants-list="page.entity.variants.data"
              :selectedVariant="selectedVariant"
              :fallback-image="variantFallbackImageComputed"
              :design-config="page.design_data.config"
              :design-style="page.design_data.style"
              :selected="selected"
              :variant-is-confirmed="variantIsConfirmed"
              :operation-mode="page.operationMode"
              @selectVariant="selectVariant($event.variant, $event.index)"
              :page="page"
            />
          </b-container>
          <div
            :style="{ order: order }"
            v-if="order !== 0 && selected && !is('declined')"
            id="variant-details"
            class="variant-selected"
          >
            <div
              v-if="
                page.operationMode == 3 &&
                !Array.isArray(selectedVariant.merchants_data)
              "
            >
              <template
                v-for="merchant_code in selectedVariant.products.data.map(
                  (x) => x.merchant_code
                )"
              >
                <hotel-area
                  :page="page"
                  v-if="selectedVariant.merchants_data[merchant_code]?.name"
                  :key="'hotel-area' + merchant_code"
                  :merchants-data="
                    selectedVariant.merchants_data[merchant_code]
                  "
                />
              </template>
            </div>
            <b-container class="ynv-container">
              <div
                v-if="
                  page.entity.variants.data.length > 1 &&
                  !isMobile &&
                  !variantIsConfirmed
                "
                class="variant-switcher variant-prev"
              >
                <b-button
                  :disabled="this.selectedVariant.index == 0"
                  pill
                  @click="changeVariant(-1)"
                >
                  <span class="mdi mdi-chevron-left" />
                </b-button>
              </div>
              <div
                v-if="
                  page.entity.variants.data.length > 1 &&
                  !isMobile &&
                  !variantIsConfirmed
                "
                class="variant-switcher variant-next"
              >
                <b-button
                  :disabled="
                    page.entity.variants.data.length ==
                    selectedVariant.index + 1
                  "
                  pill
                  @click="changeVariant(1)"
                >
                  <span class="mdi mdi-chevron-right" />
                </b-button>
              </div>
              <b-row>
                <b-col cols="12">
                  <div
                    class="variant-header d-flex justify-content-between mb-2"
                  >
                    <div style="flex-shrink: 3; min-width: 0">
                      <div
                        class="d-flex align-content-center"
                        style="flex-flow: row wrap; flex-shrink: 3"
                      >
                        <div class="mb-1" style="flex-shrink: 3; min-width: 0">
                          <div v-if="showExpirationDate" class="mb-2">
                            <span
                              class="badge badge-secondary_c expiration-badge text-wrap"
                            >
                              <template
                                v-if="
                                  $moment(new Date()).diff(
                                    $moment(page.entity.expires_at).format()
                                  ) < 0
                                "
                              >
                                <span
                                  v-html="
                                    placeholder(
                                      '%label.offer.expiring%',
                                      $t('entity.valid_till')
                                    ) +
                                    ' ' +
                                    formatDate(page.entity.expires_at) +
                                    ' '
                                  "
                                />
                                <span>{{
                                  $t("entity.expiration_time", {
                                    time: $moment(
                                      page.entity.expires_at
                                    ).format("LT")
                                  })
                                }}</span>
                              </template>
                            </span>
                          </div>

                          <div
                            :class="
                              selectedVariant.title
                                ? ''
                                : 'd-flex flex-column-reverse'
                            "
                          >
                            <div class="offer-details">
                              <div
                                v-if="selectedVariant.title"
                                class="offer-details-slug mt-1 d-inline-block"
                              >
                                <span
                                  v-html="
                                    placeholder(
                                      '%label.offer.title%',
                                      $t('entity.offer')
                                    )
                                  "
                                />:
                                {{ page.entity.slug }}
                              </div>
                              <div
                                v-if="
                                  page.entity.variants.data.length != 1 &&
                                  !variantIsConfirmed
                                "
                                class="offer-details-entity_of d-inline-block"
                              >
                                <span
                                  v-html="
                                    placeholder(
                                      '%label.offer.variant%',
                                      $t('entity.variant')
                                    )
                                  "
                                />
                                {{
                                  $t("entity.entity_of", {
                                    n: selectedVariant.index + 1,
                                    count: page.entity.variants.data.length
                                  })
                                }}
                              </div>
                            </div>
                            <div
                              class="variant-title-wrapper d-md-flex align-items-center"
                              style="
                                font-size-adjust: 0.58;
                                flex-shrink: 3;
                                min-width: 0;
                              "
                            >
                              <h2
                                style="
                                  overflow: hidden;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                "
                                v-html="
                                  selectedVariant.title
                                    ? selectedVariant.title
                                    : placeholder(
                                        '%label.offer.title%',
                                        $t('entity.offer')
                                      ) +
                                      ': ' +
                                      page.entity.slug
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex justify-self-start"
                          style="flex-shrink: 2"
                        >
                          <!-- Highligth Badge -->
                          <b-badge
                            v-if="showHighlightBadgeComputed"
                            class="ml-5 highlight-badge"
                            :class="{
                              'custom-italic':
                                'badges' in page.design_data.style &&
                                'highlight_italic' in
                                  page.design_data.style.badges &&
                                page.design_data.style.badges.highlight_italic,
                              'custom-bold':
                                'badges' in page.design_data.style &&
                                'highlight_bold' in
                                  page.design_data.style.badges &&
                                page.design_data.style.badges.highlight_bold
                            }"
                            variant="info"
                            v-html="
                              placeholder(
                                '%label.offer.variant.highlight%',
                                $t('entity.highlight')
                              )
                            "
                          />
                          <!-- Custom Badges -->
                          <div
                            v-else-if="
                              page.entity.variants.data.length == 1 &&
                              page.entity.variants.data[0].badges
                            "
                            class="d-flex justify-content-start ml-3"
                            style="flex-shrink: 3; flex-flow: row wrap"
                          >
                            <div
                              v-for="(badge, idx) in page.entity.variants
                                .data[0].badges.data"
                              :key="idx"
                              class="position-relative mr-4 mb-3"
                              style="width: 200px; height: 36px"
                            >
                              <div class="offermarker-wrapper2">
                                <div
                                  :style="[getOfferMarkerStyle(badge.style)]"
                                  :class="'badge-' + badge.style.type"
                                  class="d-flex justify-content-center align-items-center offermarker"
                                >
                                  {{ badge.title }}
                                </div>
                                <div class="triagle-wrapper">
                                  <!-- Triangles for the type ribbon -->
                                  <div
                                    v-if="badge.style.type == 'ribbon'"
                                    class="ribbon-triangles"
                                  >
                                    <div
                                      :style="[
                                        {
                                          borderTop:
                                            'solid 22px' +
                                            badge.style['background-color']
                                        }
                                      ]"
                                      class="ribbon-triangle-top"
                                    ></div>
                                    <div
                                      :style="[
                                        {
                                          borderBottom:
                                            'solid 22px' +
                                            badge.style['background-color']
                                        }
                                      ]"
                                      class="ribbon-triangle-bottom"
                                    ></div>
                                  </div>
                                  <!-- Triangles for the type banner -->
                                  <div
                                    v-else-if="badge.style.type == 'banner'"
                                    :style="{
                                      borderTop:
                                        '10px solid ' +
                                        badge.style['background-color']
                                    }"
                                    class="banner-triangle expanded-banner-triangle"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Price & Reservationbutton -->
                    <div
                      class="d-none ml-5 d-md-flex pt-3 pt-sm-0 justify-content-end align-bottom flex-shrink-0"
                    >
                      <div class="price-container mr-0 mr-md-4">
                        <div class="price-label">
                          <span
                            v-html="
                              placeholder(
                                '%label.amount%',
                                $t('entity.price.total_price')
                              )
                            "
                          />
                        </div>
                        <div
                          class="price-value"
                          v-if="selectedVariant.total_amount"
                        >
                          {{
                            formatPrice(
                              Number(selectedVariant.total_amount) +
                                calcTotalAmountOfServices()
                            )
                          }}
                        </div>
                      </div>
                      <div class="price-button" v-if="showButton">
                        <BookButton
                          ref="targetOffer"
                          :btnDisabled="btnDisabled"
                          :page="page"
                          :selectedVariantRef="selectedVariant.ref"
                          :lpUrl="getLpUrl"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="selectedVariant.description"
                    v-html="selectedVariant.description"
                    class="variant-desc mb-4"
                  />
                  <div class="mb-4">
                    <div
                      v-if="
                        $moment(new Date()).diff(
                          $moment(
                            selectedVariant.cancelpolicy_data.refundable_until
                          ).format()
                        ) < 0 &&
                        'content' in page.design_data.config &&
                        'badge_cancellation' in
                          page.design_data.config.content &&
                        page.design_data.config.content.badge_cancellation
                      "
                      class="mt-2"
                    >
                      <span
                        class="badge badge-success cancel_policy-badge text-wrap"
                      >
                        <span
                          v-html="
                            placeholder(
                              '%label.cancel_policy.free_cancellation_until%',
                              $t('entity.cancel_policy.free_cancellation_until')
                            )
                          "
                        /><br />
                        <span>{{
                          formatDate(
                            selectedVariant.cancelpolicy_data.refundable_until
                          )
                        }}</span>
                      </span>
                    </div>
                  </div>
                  <template
                    v-if="
                      selectedVariant.products &&
                      selectedVariant.products.data &&
                      selectedVariant.products.data.filter(
                        (r) => r.type == 'room'
                      ).length > 0
                    "
                  >
                    <rooms
                      :page="page"
                      :roomsList="
                        selectedVariant.products.data.filter(
                          (r) => r.type == 'room'
                        )
                      "
                      :selectedVariant="selectedVariant"
                      :designData="page.design_data.style.general"
                      :roomGalleryStart="roomGalleryStart"
                    />
                  </template>
                </b-col>
              </b-row>
              <treatments
                v-if="treatmentsDataComputed && page.ready"
                :style-data="page.design_data"
                :treatments-data="treatmentsDataComputed"
                :page="page"
                class="standard-row-space"
              />
              <b-row class="standard-row-space">
                <b-col>
                  <div
                    class="payment-hint mb-0"
                    :class="{ 'mt-0': priceTableStateComputed == 'open' }"
                  >
                    <pricetable
                      v-if="priceTableStateComputed == 'open'"
                      :selectedVariant="selectedVariant"
                      :page="page"
                      class="pricetable-opened mt-0"
                    />
                    <div
                      :class="{
                        'mt-5': priceTableStateComputed == 'open',
                        'flex-wrap':
                          priceTableStateComputed == 'closed' &&
                          cancelPolicyStateComputed == 'open'
                      }"
                      class="mb-4 mb-md-0 show-payment-table d-flex flex-column flex-md-row justify-content-md-between"
                    >
                      <div
                        class="order-0 order-md-0 order-lg-0 order-xl-0 mt-2"
                      >
                        <template v-if="priceTableStateComputed == 'open'">
                          <b-btn
                            v-if="!(cancelPolicyStateComputed == 'open')"
                            class="price-cancel-policy-button"
                            href="#"
                            @click="$bvModal.show('show-cancellation_policy')"
                          >
                            {{ $t("entity.cancel_policy.show") }}
                          </b-btn>
                        </template>
                        <template v-else>
                          <b-btn
                            class="price-cancel-policy-button"
                            @click.prevent="$bvModal.show('show-price-table')"
                            href="#"
                            :class="{
                              'custom-italic':
                                'rooms' in page.design_data.style &&
                                'storno_italic' in
                                  page.design_data.style.rooms &&
                                page.design_data.style.rooms.storno_italic,
                              'custom-bold':
                                'rooms' in page.design_data.style &&
                                'storno_bold' in page.design_data.style.rooms &&
                                page.design_data.style.rooms.storno_bold
                            }"
                          >
                            {{
                              cancelPolicyStateComputed == "open"
                                ? $t("entity.price.show_price_details_short")
                                : $t("entity.price.show_price_details")
                            }}
                          </b-btn>
                        </template>
                      </div>
                      <b-row
                        v-if="cancelPolicyStateComputed == 'open'"
                        :class="{
                          'order-1 order-md-2 order-lg-2 order-xl-2':
                            priceTableStateComputed == 'closed'
                        }"
                        class="w-100"
                      >
                        <b-col
                          cols="12"
                          md="8"
                          lg="6"
                          class="cancel-policy-show-open px-3"
                          :class="
                            priceTableStateComputed == 'closed'
                              ? 'mt-4 mt-md-3 py-sm-2 py-md-0'
                              : 'mt-2'
                          "
                        >
                          <h2>{{ $t("entity.cancel_policy.title") }}</h2>
                          <div
                            v-html="
                              selectedVariant.cancelpolicy_data.description
                            "
                            class="cancel-policy-text"
                          />
                        </b-col>
                      </b-row>
                      <div
                        class="secure-badge pb-2 pb-md-0 pt-2 pt-md-0 text-md-right order-2 order-sm-2 order-md-1 order-lg-1 order-xl-1"
                      >
                        <img src="@/assets/secure-payment.png" height="60" />
                      </div>
                    </div>
                  </div>
                  <div class="payment-total mt-5 d-sm-flex">
                    <div
                      v-if="showDepositBalanceComputed"
                      class="border-right deposit_balance mt-3"
                    >
                      <div class="label">
                        <span
                          v-html="
                            placeholder(
                              '%label.deposit_balance%',
                              $t('entity.price.deposit_balance')
                            )
                          "
                        />
                      </div>
                      <div class="value pt-2 pt-sm-3">
                        {{
                          selectedVariant.deposit_balance &&
                          formatPrice(selectedVariant.deposit_balance)
                        }}
                      </div>
                    </div>
                    <div
                      v-if="showBalanceComputed && showDepositAmountComputed"
                      class="border-right balance mt-3"
                    >
                      <div class="label">
                        <span
                          v-html="
                            placeholder(
                              '%label.balance%',
                              $t('entity.price.balance')
                            )
                          "
                        />
                      </div>
                      <div class="value pt-2 pt-sm-3">
                        {{
                          selectedVariant.total_amount &&
                          selectedVariant.deposit_amount &&
                          formatPrice(selectedVariant.total_balance)
                        }}
                      </div>
                    </div>
                    <div
                      v-if="showDepositAmountComputed"
                      class="border-right deposit_amount mt-3"
                    >
                      <div class="label">
                        <span
                          v-html="
                            placeholder(
                              '%label.deposit%',
                              $t('entity.price.deposit_amount')
                            )
                          "
                        />
                      </div>
                      <div class="value pt-2 pt-sm-3">
                        {{
                          recalculateDepositIfUpselling(
                            selectedServices,
                            calcTotalAmountOfServices()
                          )
                        }}
                      </div>
                    </div>
                    <div class="total_price mt-3 mb-2">
                      <div class="label">
                        <span
                          v-html="
                            placeholder(
                              '%label.amount%',
                              $t('entity.price.total_price')
                            )
                          "
                        />
                      </div>
                      <div class="value pt-2 pt-sm-3">
                        {{
                          selectedVariant.total_amount &&
                          formatPrice(
                            Number(selectedVariant.total_amount) +
                              calcTotalAmountOfServices()
                          )
                        }}
                      </div>
                    </div>
                  </div>
                  <ExecutedPayments
                    class="pt-5 pb-1"
                    :page="page"
                    :lpLocale="lpLocale"
                  />
                  <template
                    v-if="
                      reservation_process == 'onestep' &&
                      (!page.entity.expires_at ||
                        (page.entity.expires_at &&
                          $moment(new Date()).diff(
                            $moment(page.entity.expires_at).format()
                          ) < 0)) &&
                      (is('option') || is('payment_available'))
                    "
                  >
                    <OneStepPayment
                      :selected-variant="selectedVariant"
                      :page="page"
                      :btnDisabled="btnDisabled"
                    />
                  </template>
                  <template
                    v-if="
                      page.entity.payments.data.find(
                        (p) => p.provider_slug?.toLowerCase() == 'banktransfer'
                      ) &&
                      selectedVariant.paymethods.data.find(
                        (pm) =>
                          pm.method_id ==
                          page.entity.payments.data.find(
                            (p) =>
                              p.provider_slug?.toLowerCase() == 'banktransfer'
                          )?.method_id
                      ) &&
                      [
                        'crm.campaign.order.confirmed',
                        'crm.campaign.order.cancellation'
                      ].indexOf(page.entity.scope) == -1
                    "
                  >
                    <div
                      v-html="
                        placeholderString(
                          selectedVariant.paymethods.data.find(
                            (pm) =>
                              pm.method_id ==
                              page.entity.payments.data.find(
                                (p) =>
                                  p.provider_slug?.toLowerCase() ==
                                  'banktransfer'
                              )?.method_id
                          )?.description,
                          {
                            deposit_balance: formatPrice(
                              selectedVariant.deposit_balance > 0
                                ? selectedVariant.deposit_balance
                                : selectedVariant.deposit_amount
                            )
                          }
                        )
                      "
                      class="mt-3"
                    />
                  </template>
                  <div class="payment-action">
                    <span id="tooltip-button-disable-1" v-if="showButton">
                      <BookButton
                        ref="targetMain"
                        :btnDisabled="btnDisabled"
                        :page="page"
                        :selectedVariantRef="selectedVariant.ref"
                        :lpUrl="getLpUrl"
                        data-cy="paymentActionBookButton"
                      />
                    </span>
                    <b-tooltip
                      :disabled="!btnDisabled"
                      ref="tooltip"
                      target="tooltip-button-disable-1"
                    >
                      {{ $t("entity.payment.select_method") }}
                    </b-tooltip>
                    <b-btn
                      v-if="!isMobile"
                      :class="{ 'ml-4': showButton }"
                      class="print-btn"
                      @click="printAction()"
                    >
                      <svg
                        id="Gruppe_6341"
                        class="icon"
                        :fill="
                          page.design_data.style.cta.sec_btn_font_color ||
                          page.design_data.style.cta.cta_color ||
                          '#63AD56'
                        "
                        data-name="Gruppe 6341"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26.461"
                        height="24.488"
                        viewBox="0 0 26.461 24.488"
                      >
                        <g id="Gruppe_6338" data-name="Gruppe 6338">
                          <g
                            id="Gruppe_6324"
                            data-name="Gruppe 6324"
                            transform="translate(0 3.575)"
                          >
                            <g id="Gruppe_6323" data-name="Gruppe 6323">
                              <path
                                id="Pfad_1751"
                                data-name="Pfad 1751"
                                transform="translate(-73.2 -225)"
                                d="M97.847,228.461v8.9a12.049,12.049,0,0,1-.021,1.439c.012-.081.021-.16.033-.241a2.609,2.609,0,0,1-.178.661l.091-.217a2.622,2.622,0,0,
                                                                1-.223.416c-.021.033-.045.063-.066.1-.091.13.151-.178.045-.06-.051.057-.1.118-.16.175s-.115.106-.175.16c-.112.1.187-.133.06-.045-.033.021-.063.045-.1.066a2.519,
                                                                2.519,0,0,1-.416.223l.217-.091a2.68,2.68,0,0,1-.661.178l.241-.033a10.737,10.737,0,0,1-1.322.021H92.881l.905.905v-6.677a.918.918,0,0,0-.905-.905h-11.3c-.522,
                                                                0-1.044-.012-1.563,0H80a.918.918,0,0,0-.905.905v6.677L80,240.11H76.968a5.429,5.429,0,0,1-.628-.021l.241.033a2.611,2.611,0,0,1-.661-.178l.217.091a2.628,2.628,0,0,
                                                                1-.416-.223c-.033-.021-.063-.045-.1-.066-.13-.091.178.151.06.045-.057-.051-.118-.1-.175-.16s-.106-.115-.16-.175c-.1-.112.133.187.045.06-.021-.033-.045-.063-.066-.1a2.515,
                                                                2.515,0,0,1-.223-.416l.091.217a2.679,2.679,0,0,1-.178-.661c.012.081.021.16.033.241a10.632,10.632,0,0,1-.021-1.24v-7.953c0-.38,0-.763,0-1.143a3.12,3.12,0,0,1,
                                                                .021-.335c-.012.081-.021.16-.033.241a2.61,2.61,0,0,1,.178-.661l-.091.217a2.626,2.626,0,0,1,
                                                                .223-.416c.021-.033.045-.063.066-.1.091-.13-.151.178-.045.06.051-.057.1-.118.16-.175s.115-.106.175-.16c.112-.1-.187.133-.06.045.033-.021.063-.045.1-.066a2.517,
                                                                2.517,0,0,1,.416-.223l-.217.09a2.68,2.68,0,0,1,.661-.178l-.241.033a6.331,6.331,0,0,1,.793-.021H93.916c.763,0,1.524,0,2.287,0a2.726,2.726,0,0,1,.35.021l-.241-.033a2.612,
                                                                2.612,0,0,1,.661.178l-.217-.09a2.633,2.633,0,0,1,
                                                                .416.223c.033.021.063.045.1.066.13.09-.178-.151-.06-.045.057.051.118.1.175.16s.106.115.16.175c.1.112-.133-.187-.045-.06.021.033.045.063.066.1a2.514,
                                                                2.514,0,0,1,.223.416l-.091-.217a2.681,2.681,0,0,1,.178.661c-.012-.082-.021-.16-.033-.241,0,.109,0,.22.006.332a.905.905,0,1,0,1.81,0,3.483,3.483,0,0,
                                                                0-2.845-3.4,6.654,6.654,0,0,0-1.038-.06H76.712a3.529,3.529,0,0,0-2.963,1.59,3.876,3.876,0,0,0-.549,2.221v9.637a3.45,3.45,0,0,0,2.335,3.292,3.794,3.794,0,0,0,
                                                                1.312.19h3.138a.918.918,0,0,0,.905-.905v-6.677l-.905.905h11.3c.519,0,1.044.015,1.563,0h.021l-.905-.905v6.677a.918.918,0,0,0,.905.905h3.246a3.5,3.5,0,0,0,2.7-1.2,3.6,3.6,
                                                                0,0,0,.842-2.353V231.14c0-.878.012-1.756,0-2.634v-.039a.9.9,0,0,0-1.807-.006Z"
                              />
                            </g>
                          </g>
                          <g
                            id="Gruppe_6326"
                            data-name="Gruppe 6326"
                            transform="translate(5.883)"
                          >
                            <g id="Gruppe_6325" data-name="Gruppe 6325">
                              <path
                                id="Pfad_1752"
                                data-name="Pfad 1752"
                                transform="translate(-268.2 -106.522)"
                                d="M281.08,107.433V111l.905-.905h-11.3c-.519,0-1.044-.015-1.563,0h-.021l.905.905v-3.569l-.905.905h11.3c.522,0,1.044.012,1.563,0h.021a.905.905,0,1,0,0-1.81h-11.3c-.522,
                                                                0-1.044-.012-1.563,0h-.021a.918.918,0,0,0-.905.905V111a.918.918,0,0,0,.905.905h11.3c.522,0,1.044.012,1.563,0h.021a.918.918,0,0,0,.905-.905v-3.569a.905.905,0,0,0-1.81,0Z"
                              />
                            </g>
                          </g>
                          <g
                            id="Gruppe_6328"
                            data-name="Gruppe 6328"
                            transform="translate(5.879 12.008)"
                          >
                            <g id="Gruppe_6327" data-name="Gruppe 6327">
                              <path
                                id="Pfad_1753"
                                data-name="Pfad 1753"
                                transform="translate(-268.067 -504.522)"
                                d="M280.951,505.433v9.347c0,.431-.012.866,0,1.294v.018l.905-.9h-11.3c-.519,0-1.044-.015-1.563,0h-.021l.905.9v-9.347c0-.431.012-.866,0-1.294v-.018l-.905.905h11.3c.522,0,1.044.012,1.563,
                                                                0h.021a.905.905,0,0,0,0-1.81h-11.3c-.522,0-1.044-.012-1.563,0h-.021a.918.918,0,0,0-.905.905v9.347c0,.431-.009.863,0,1.294v.018a.918.918,0,0,0,.905.905h11.3c.522,0,1.044.012,1.563,
                                                                0h.021a.918.918,0,0,0,.905-.905v-9.347c0-.431.009-.863,0-1.294v-.018a.905.905,0,0,0-1.81,0Z"
                              />
                            </g>
                          </g>
                          <g
                            id="Gruppe_6332"
                            data-name="Gruppe 6332"
                            transform="translate(18.521 7.82)"
                          >
                            <g id="Gruppe_6331" data-name="Gruppe 6331">
                              <path
                                id="Pfad_1755"
                                data-name="Pfad 1755"
                                d="M687.982,367.51h1.991a.905.905,0,0,0,0-1.81h-1.991a.905.905,0,0,0,0,1.81Z"
                                transform="translate(-687.077 -365.7)"
                              />
                            </g>
                          </g>
                          <g
                            id="Gruppe_6337"
                            data-name="Gruppe 6337"
                            transform="translate(9.829 15.725)"
                          >
                            <g
                              id="Gruppe_6334"
                              data-name="Gruppe 6334"
                              transform="translate(0)"
                            >
                              <g id="Gruppe_6333" data-name="Gruppe 6333">
                                <path
                                  id="Pfad_1756"
                                  data-name="Pfad 1756"
                                  d="M399.882,629.51h4.987a.905.905,0,0,0,0-1.81h-4.987a.905.905,0,1,0,0,1.81Z"
                                  transform="translate(-398.976 -627.7)"
                                />
                              </g>
                            </g>
                            <g
                              id="Gruppe_6336"
                              data-name="Gruppe 6336"
                              transform="translate(0 3.228)"
                            >
                              <g id="Gruppe_6335" data-name="Gruppe 6335">
                                <path
                                  id="Pfad_1757"
                                  data-name="Pfad 1757"
                                  d="M399.882,736.51h3.494a.905.905,0,1,0,0-1.81h-3.494a.905.905,0,1,0,0,1.81Z"
                                  transform="translate(-398.976 -734.7)"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </b-btn>
                  </div>

                  <div
                    v-if="selectedVariant.price_note"
                    :class="{
                      'custom-italic':
                        'rooms' in page.design_data.style &&
                        'price_italic' in page.design_data.style.rooms &&
                        page.design_data.style.rooms.price_italic,
                      'custom-bold':
                        'rooms' in page.design_data.style &&
                        'price_bold' in page.design_data.style.rooms &&
                        page.design_data.style.rooms.price_bold
                    }"
                    class="price-info mt-4"
                  >
                    <span v-html="selectedVariant.price_note" />
                  </div>
                </b-col>
              </b-row>
              <insurance
                v-if="
                  is('confirmed') &&
                  'travel_insurance_company' in page &&
                  page.travel_insurance_company != '' &&
                  'insurance_url' in selectedVariant &&
                  selectedVariant.insurance_url != ''
                "
                :insurance_text="
                  placeholder(
                    '%label.offer.insurance.text%',
                    $t('entity.insurance.text')
                  )
                "
                :insurance_cta="
                  placeholder(
                    '%label.offer.insurance.ctabtn%',
                    $t('entity.insurance.ctabtn')
                  )
                "
                :insurance_data="selectedVariant.insurance_url"
              />
            </b-container>
          </div>
          <personal-information
            v-if="
              page.entity.order_customer &&
              ((!is('declined') && reservation_process == 'onestep') ||
                is('confirmed'))
            "
            :customer-information="page.entity.order_customer"
            :page="page"
          />
          <b-container
            v-if="hasHighlights"
            class="ynv-container highlights"
            id="highlights"
          >
            <highlights
              :title="
                placeholder(
                  '%label.highlights.title%',
                  $t('entity.highlights_header')
                )
              "
              :design-data="page.design_data"
              :datesForSkyAlps="datesForSkyAlps"
              :config="page.sections.highlights"
              :content="page.elements.highlights_raw"
              :highlightContentLoaded="highlightContentLoaded"
            />
          </b-container>
          <b-col
            id="receptionistBottom"
            cols="12"
            v-if="editorPositionComputed == 'bottom'"
          >
            <div class="d-flex justify-content-center my-5">
              <editor
                :page="page"
                v-if="page.entity.editor.data"
                :isSidebar="false"
                :editor-data="page.entity.editor.data"
                :account-data="page.account"
              />
            </div>
          </b-col>
          <Footer
            id="footerContainer"
            v-if="page.sections.footer && page.sections.footer.overwrite != 3"
            :style="{ 'margin-bottom': !is('disabled_cta_bar') ? '100px' : '' }"
            :config-data="{
              sections: page.sections.footer || null,
              map: page.map || null,
              account: page.account || null,
              elements: page.elements.footer || null,
              design_config: {
                footer: page.design_data.config.footer || null,
                navigation: page.design_data.config.navigation || null
              },
              entity: page.entity || null
            }"
            :page="page"
          />
        </div>
        <transition name="slide">
          <CTABar
            v-if="!isHiddenCTABar"
            :fixedFooterVisible="fixedFooterVisible"
            :selectedVariant="selectedVariant"
            :page="page"
            :showButton="showButton"
            :reservation_process="reservation_process"
            :lpUrl="getLpUrl"
          />
        </transition>
        <b-modal
          id="show-cancellation_policy"
          centered
          scrollable
          header-class="modal-cancel-p-header"
          body-class="modal-cancel-p-body"
        >
          <template #modal-title>
            <h3>{{ $t("entity.cancel_policy.title") }}</h3>
          </template>
          <div class="px-2 pb-3">
            <div v-html="selectedVariant.cancelpolicy_data.description" />
          </div>
        </b-modal>
        <b-modal
          id="show-price-table"
          modal-class="top5px"
          size="xl"
          centered
          hide-header
          @hidden="hideBtnBook = false"
        >
          <div class="closeButton">
            <button @click="hideModalAndShowBtn()">
              <span class="closeIcon"><b-icon icon="x"></b-icon></span>
            </button>
          </div>
          <div class="d-md-flex mb-4">
            <h2
              class="mb-0 mb-md-4 mr-4"
              v-html="
                selectedVariant.title
                  ? selectedVariant.title
                  : placeholder('%label.offer.title%', $t('entity.offer')) +
                    ': ' +
                    page.entity.slug
              "
            />
            <div class="extras mb-4 mb-md-0 mt-md-0 mt-2">
              <div
                v-if="
                  $moment(new Date()).diff(
                    $moment(
                      selectedVariant.cancelpolicy_data.refundable_until
                    ).format()
                  ) < 0
                "
              >
                <span
                  v-if="
                    'content' in page.design_data.config &&
                    'badge_cancellation' in page.design_data.config.content &&
                    page.design_data.config.content.badge_cancellation
                  "
                  v-html="
                    placeholder(
                      '%label.cancel_policy.free_cancellation%',
                      $t('entity.cancel_policy.free_cancellation_until')
                    )
                  "
                  class="badge badge-success cancel_policy-badge alt text-wrap"
                />
              </div>
              <div v-if="selectedVariant.deposit_amount == 0">
                <span
                  v-if="
                    'content' in page.design_data.config &&
                    'badge_deposit' in page.design_data.config.content &&
                    page.design_data.config.content.badge_deposit
                  "
                  v-html="
                    placeholder(
                      '%label.deposit_policy.no_deposit_needed%',
                      $t('entity.no_deposit_needed')
                    )
                  "
                  class="badge badge-secondary no_deposit-badge"
                />
              </div>
            </div>
          </div>
          <pricetable :selected-variant="selectedVariant" :page="page" />
          <template v-slot:modal-footer>
            <div class="d-block d-sm-flex justify-content-between w-100">
              <div class="booking-info">
                <div
                  :class="{
                    'custom-italic':
                      'rooms' in page.design_data.style &&
                      'price_italic' in page.design_data.style.rooms &&
                      page.design_data.style.rooms.price_italic,
                    'custom-bold':
                      'rooms' in page.design_data.style &&
                      'price_bold' in page.design_data.style.rooms &&
                      page.design_data.style.rooms.price_bold
                  }"
                  class="hint pb-3"
                >
                  <span v-html="selectedVariant.price_note" />
                </div>
              </div>
              <div
                class="booking-action text-center ml-md-4 mt-4 mt-sm-0"
                :class="{ 'd-none': hideBtnBook || !showButton }"
              >
                <BookButton
                  :btnDisabled="btnDisabled"
                  :page="page"
                  :selectedVariantRef="selectedVariant.ref"
                  :lpUrl="getLpUrl"
                />
              </div>
            </div>
            <div class="cancel-policy-show-open">
              <h2>{{ $t("entity.cancel_policy.title") }}</h2>
              <div
                v-html="selectedVariant.cancelpolicy_data.description"
                class="cancel-policy-text"
              />
            </div>
          </template>
        </b-modal>
        <b-modal
          id="show-checkin"
          hide-header
          class="pl-0"
          no-close-on-esc
        >
          <checkin :page="page" :firstLoadForSelect="firstLoadForCheckinSelect" @checkinSelect="(flag) => firstLoadForCheckinSelect = flag"/>
        </b-modal>
        <b-modal
          id="show-checkout"
          hide-header
          class="pl-0"
          v-if="selectedVariant && selected"
        >
          <Checkout @hideCheckoutBtnBook="hideBtnBook = true" />
        </b-modal>
        <b-modal
          id="show-checkout-thank-you"
          hide-header
          class="pl-0"
        >
          <div
            :style="{
              'background-image':
                'url(' +
                page.thank_you_background_img_url +
                '?d=' +
                returnImgWidth() +
                ')'
            }"
            class="wrap"
          >
            <div
              class="modal-wrapper d-flex align-items-center justify-content-center"
            >
              <div class="modal-wrapper-content">
                <span class="mdi mdi-check" />
                <h1
                  v-html="
                    placeholder(
                      '%label.thankyou.title%',
                      $t(
                        'booking.' +
                          ([
                            'crm.campaign.order.unconfirmed',
                            'crm.campaign.offer.unconfirmed',
                            'crm.campaign.offer.confirmed_byguest',
                            'crm.campaign.order.confirmed_byguest'
                          ].indexOf(page.entity.scope) >= 0
                            ? is('offer')
                              ? 'offer'
                              : 'order'
                            : 'default') +
                          '.title'
                      ),
                      true
                    )
                  "
                />
                <p
                  v-html="
                    placeholder(
                      '%label.thankyou.text%',
                      $t(
                        'booking.' +
                          ([
                            'crm.campaign.order.unconfirmed',
                            'crm.campaign.offer.unconfirmed',
                            'crm.campaign.offer.confirmed_byguest',
                            'crm.campaign.order.confirmed_byguest'
                          ].indexOf(page.entity.scope) >= 0
                            ? is('offer')
                              ? 'offer'
                              : 'order'
                            : 'default') +
                          '.text'
                      ),
                      true
                    )
                  "
                />
                <b-button @click="closeThankYou">
                  {{ $t("entity.request.thank_you.button") }}
                </b-button>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal
          id="show-checkin-thank-you"
          hide-header
          class="pl-0"
        >
          <div
            :style="{
              'background-image':
                'url(' +
                page.thank_you_background_img_url +
                '?d=' +
                returnImgWidth() +
                ')'
            }"
            class="wrap"
          >
            <div
              class="modal-wrapper d-flex align-items-center justify-content-center"
            >
              <div class="modal-wrapper-content">
                <span class="mdi mdi-check" />
                <h1
                  v-html="
                    placeholder(
                      '%label.order.checkin.confirm.title%',
                      $t('checkin.thank_you.title'),
                      true
                    )
                  "
                />
                <p
                  v-html="
                    placeholder(
                      '%label.order.checkin.confirm.text%',
                      $t('checkin.thank_you.text'),
                      true
                    )
                  "
                />
                <b-button @click="closeThankYou">
                  {{ $t("entity.request.thank_you.button") }}
                </b-button>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <sticky-contacts-menu
        v-if="page.design_data.config.floating.floating"
        :config="page.design_data.config"
        :page="page"
        :contact="page.account.contact.data"
        :fixedFooterVisible="fixedFooterVisible"
        :styleData="page.design_data.style"
        :entity="page.entity"
        :account="page.account"
        :showChat="page.lp_show_chat"
        :backendURL="backendURL"
        :hash="hash"
        :campaignId="page.entity_id"
        :placeholderWhatsapp="
          placeholder(
            '%label.whatsapp.message.placeholder%',
            this.$t('page.placeholder.whatsapp', {
              offer: page.entity.slug
            }),
            false,
            {
              offer: page.entity.slug
            }
          )
        "
        class="stickyContactsMenu"
      />
    </div>
    <b-modal id="error-message" hide-header class="pl-0">
      <div
        class="modal-wrapper d-flex align-items-center justify-content-center"
      >
        <div class="modal-wrapper-content">
          <span class="mdi mdi-close" />
          <h1>
            {{
              $t(
                showErrorPayment
                  ? "page.errors.payment.title"
                  : "page.errors.no_hash.title"
              )
            }}
          </h1>
          <p>
            {{
              showErrorPayment
                ? $t("page.errors.payment.hint") + $route.query.gateway_message
                : $t("page.errors.no_hash.text")
            }}
          </p>
          <div>
            <b-btn @click="closeThankYou"
              >{{ $t("page.errors.payment.btn_retry") }}
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="landingpage-removed" hide-header class="pl-0">
      <div
        class="modal-wrapper d-flex align-items-center justify-content-center"
      >
        <div class="modal-wrapper-content">
          <h1>Die gewünschte Seite ist nicht mehr Verfügbar</h1>
          <h1>La pagina richiesta non è più disponibile</h1>
          <h1>The requested page is no longer available</h1>
        </div>
      </div>
    </b-modal>
    <CheckoutModalCoupon
      :coupon="coupon"
      :page="page"
      :selected-variant="selectedVariant"
      :lpLocale="lpLocale"
    />
    <CheckoutModals v-if="coupon.page === 'remaining' && !hideCheckoutModals" />
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";
import { ref, onMounted } from "vue";
import { useUpselling } from "@/composables/useUpselling";

const { calcTotalAmountOfServices, selectedServices } = useUpselling();

const targetOffer = ref(null);
const targetMain = ref(null);
const isHiddenCTABar = ref(false);

onMounted(() => {
  useIntersectionObserver([targetOffer, targetMain], ([{ isIntersecting }]) => {
    isHiddenCTABar.value = isIntersecting;
  });
});
</script>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import cssVars from "css-vars-ponyfill";
import Checkin from "@/Checkin";
import Highlights from "@/components/Highlights";
import Insurance from "@/components/Insurance";
import StickyContactsMenu from "@/components/StickyContactsMenu";
import { lpMixins } from "@/mixins/lpMixins";
import Navigation from "@/components/Navigation";
import Pricetable from "@/components/Pricetable";
import CTABar from "@/components/CTABar";
import DebugBar from "@/components/DebugBar";
import CustomElement from "@/components/CustomElement";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import VariantList from "@/components/VariantList";
import Rooms from "@/components/Rooms";
import PersonalInformation from "@/components/PersonalInformation";
import Treatments from "@/components/Treatments";
import Editor from "@/components/Editor";
import PrintVersion from "@/components/PrintVersion";
import HotelArea from "@/components/HotelArea";
import ExpiredAlert from "./components/ExpiredAlert";
import Checkout from "@/components/checkout/Checkout";
import { computed } from "vue";
import CheckoutModalCoupon from "@/components/checkout/CheckoutModalCoupon";
import CheckoutModals from "@/components/checkout/CheckoutModals";
import OneStepPayment from "@/components/OneStepPayment";
import BookButton from "@/components/BookButton";
import ExecutedPayments from "@/components/ExecutedPayments";
import RedirectedLoading from "@/components/checkout/RedirectLoading";
import { captureException } from "@sentry/vue";
import { groupBy, sum } from "lodash";

export default {
  mixins: [lpMixins],
  name: "Landingpage",
  components: {
    CheckoutModalCoupon,
    Checkout,
    HotelArea,
    PrintVersion,
    CTABar,
    Footer,
    Editor,
    CustomElement,
    DebugBar,
    StickyContactsMenu,
    Pricetable,
    VariantList,
    Insurance,
    Header,
    Highlights,
    Loading,
    Checkin,
    Navigation,
    PersonalInformation,
    Rooms,
    Treatments,
    ExpiredAlert,
    CheckoutModals,
    OneStepPayment,
    BookButton,
    ExecutedPayments,
    RedirectedLoading
  },
  data() {
    return {
      variantIsConfirmed: false,
      hideCheckoutModals: false,
      order: 2,
      selected: false,
      selectedVariant: { index: 0 },
      fallbackLocale: "en",
      lpLocale: "",
      complete_payment: false,
      coupon: {
        page: "default",
        progress: false,
        code: null,
        modal: false,
        amount: 0,
        confirmURL: null
      },
      checkin: true,
      hideBtnBook: false,
      selectedVariantVisible: false,
      selectedVariantVisible1: false,
      showErrorPayment: false,
      carouselRatio: window.innerWidth < 767 ? 0.87 : 2.36,
      roomGalleryStart: [],
      isLoading: true,
      fullPage: true,
      visitTimeout: 5000,
      highlightContentLoaded: [],
      blockContentLoaded: [],
      navigation: {
        mainNav: []
      },
      page: {
        ready: false,
        account: {},
        elements: {
          header: {},
          content: {},
          content_raw: {},
          highlights: {},
          highlights_raw: {},
          footer: {},
          subject: {},
          attachments: {}
        },
        design_data: {},
        entity: {},
        sections: {},
        operationMode: null,
        locale: null,
        hash: null,
        entity_id: null,
        entity_type: null,
        template_id: null,
        offer_show_rooms: null,
        config: {},
        upselling_enabled: false,
        mobile_variants_side_by_side: true,
        deposit_rounding: 0
      },
      selectedPaymentMethod: false,
      request: {
        salutation: "",
        firstname: "",
        lastname: "",
        gender: "",
        email: "",
        phone: "",
        lang: "",
        street: "",
        zip: "",
        city: "",
        country: "",
        note: "",
        newsletter: null
      },
      form: {
        terms: false,
        newsletter: false
      },
      reservation_process: "",
      confirmation_config: "top",
      reservation_process_start: false,
      mainLocales: ["it", "de", "fr", "en", "ru", "es"],
      account: null,
      entity: null,
      upselling: null,
      preselectedServices: null,
      firstLoadForCheckinSelect: true
    };
  },
  provide() {
    return {
      page: this.page,
      upselling: computed(() => this.upselling),
      deposit_rounding: computed(() => this.page.deposit_rounding),
      preselectedServices: computed(() => this.preselectedServices),
      request: this.request,
      selectedVariant: computed(() => this.selectedVariant),
      reservation_process: computed(() => this.reservation_process),
      renderData: (val) => this.renderData(val),
      placeholder: (
        placeholder,
        fallback = false,
        forceScope = false,
        params = {}
      ) => this.placeholder(placeholder, fallback, forceScope, params),
      btnDisabled: computed(() => this.btnDisabled),
      coupon: computed(() => this.coupon),
      complete_payment: computed({
        get: () => this.complete_payment,
        set: (val) => (this.complete_payment = val)
      }),
      lpLocale: computed(() => this.lpLocale),
      showErrorPayment: computed(() => this.showErrorPayment),
      showDepositAmountComputed: computed(() => this.showDepositAmountComputed),
      hideBtnBook: computed(() => this.hideBtnBook),
      isTooDark: (val) => this.isTooDark(val)
    };
  },
  computed: {
    getLpUrl() {
      return this.backendURL + this.hash;
    },
    hasHighlights() {
      const highlights = this.page?.elements?.highlights;
      return (
        highlights?.gallery?.length ||
        highlights?.block?.length ||
        highlights?.["block.html"]?.length ||
        highlights?.card?.length
      );
    },
    datesForSkyAlps() {
      const { from_date, to_date } = this.page.entity.variants.data[0];
      return { from_date, to_date };
    },
    showExpirationDate() {
      const { entity, design_data } = this.page;
      return (
        entity.expires_at &&
        this.scopes.expired.includes(entity.scope) &&
        design_data.config?.content?.badge_expiration
      );
    },
    showDepositBalanceComputed() {
      return (
        this.selectedVariant.deposit_balance > 0 &&
        this.selectedVariant.deposit_balance !=
          this.selectedVariant.deposit_amount
      );
    },
    showBalanceComputed() {
      return this.page.entity.scope == "crm.campaign.order.confirmed";
    },
    showDepositAmountComputed() {
      return this.selectedVariant.deposit_required;
    },
    editorPositionComputed() {
      return this.page?.design_data?.config?.content?.editor_position || "top";
    },
    navigationPositionComputed() {
      return this.page?.design_data?.config?.settings?.nav_position || "bottom";
    },
    showHighlightBadgeComputed() {
      return (
        this.selectedVariant.highlight ||
        this.query_options?.show_variant_highlight_badge ||
        false
      );
    },
    variantFallbackImageComputed() {
      return "gallery" in this.page.elements.header
        ? this.page.elements?.header?.gallery[0]?.content_data?.data?.media?.find(
            (el) => el.type == "image"
          )?.public_url
        : "";
    },
    fixedFooterVisible() {
      return (
        !this.is("disabled_cta_bar") &&
        this.selected &&
        this.selectedVariant &&
        !this.selectedVariantVisible &&
        !this.selectedVariantVisible1
      );
    },
    showButton() {
      let hasCheckinData =
        this.is("checkin") &&
        "checkin" in this.page.entity &&
        this.page.entity.checkin.data
          ? true
          : false;
      return (!this.is("disabled_button") &&
        this.selectedVariant.booking_action &&
        this.selectedVariant.booking_action != "expired") ||
        hasCheckinData
        ? true
        : false;
    }
  },
  watch: {
    $route(to, from) {
      this.showOrHideRecaptcha(to);
      if (to.hash == "#checkout") {
        this.$router.push({ name: "checkout" });
      }
      if (to.name == "checkout") {
        this.showButton && this.$bvModal.show("show-checkout");
        this.hideCheckoutModals = true;
      }
      if (to.name == "checkin-done") {
        this.$bvModal.hide("show-checkin");
        this.$bvModal.show("show-checkin-thank-you");
      }
      if (to.name == "checkin") {
        this.$bvModal.show("show-checkin");
      }
      if (from.hash == "#checkout" && to.hash != "#checkout") {
        this.$bvModal.hide("show-checkout");
      }
      if (from.name == "checkout" && to.name != "checkout") {
        this.$bvModal.hide("show-checkout");
      }
    }
  },
  created() {
    let defaultCssSet = {
      "--general_font-family": "'Open Sans', sans-serif",
      "--general_font-color": "#414141",
      "--general_title_font_color": "#414141",
      "--general_h1_font_color": "#414141",
      "--general_background_color": "#FFFFFF",
      "--general_sale_background_color": "#FAFAFA",
      "--general_secondary_background_color": "#FAFAFA",
      "--general_radius": "10px",
      "--cta_cta_color": "#63AD56",
      "--cta_btn_font_color": "#fff",
      "--cta_radius": "5px",
      "--cta_sec_btn_background": "",
      "--cta_sec_btn_font_color": "",
      "--cta_bar_background": "#404040",
      "--cta_bar_font_color": "",
      "--navigation_normal_color": "#414141",
      "--navigation_normal_background_color": "white",
      "--navigation_focus_color": "#414141",
      "--navigation_focus_background_color": "white",
      "--rooms_change_btn_background_color": "#28A745",
      "--rooms_change_btn_font_color": "white",
      "--floating_icons_main_color": "#63AD56",
      "--floating_icons_phone_color": "#3B5EA2",
      "--floating_icons_mail_color": "#D4830A",
      "--floating_icons_whatsapp_color": "#42A446"
    };
    cssVars({ variables: defaultCssSet });

    let hash = this.$route.params.hash ? this.$route.params.hash : null;
    if (hash) {
      let url =
        (this.hash_type == "normal"
          ? this.backendURL + hash
          : this.backendURL + hash + "/versions/" + this.hash) +
        "?entity_enhance=1";
      this.axios
        .get(url)
        .then((response) => {
          let responseData = this.renderData(response.data.data);
          this.$forceUpdate();
          const config = responseData.config;
          if (config && "display_offer" in config) {
            if (config.display_offer === 1) {
              this.order = 6;
            }
            if (config.display_offer === 2) {
              this.order = 0;
            }
          }
          document.title = this.page.account.name;
          this.page.ready = true;
          this.isLoading = false;

          /**
           * booking_action: "payment_balance" -> The "booking_action" property has the value "payment_balance" when the deposit
           * is not paid in full
           */
          const bookingAction = this.page.entity.variants.data.some(({ booking_action }) => booking_action === 'payment_balance');
          if(bookingAction) this.$router.push({name: 'hashed'});

          if (typeof this.$route.query.action != "undefined") {
            if (
              this.$route.query.action == "payment_confirmed" &&
              ((typeof this.$route.query.status != "undefined" &&
                this.$route.query.status == "PAID") ||
                this.$route.query.status == "AUTHORIZED")
            ) {
              setTimeout(() => {
                this.$bvModal.show("show-checkout-thank-you");
              }, 100);
            } else {
              console.log("Error di pagamento");
              this.showErrorPayment = true;
              setTimeout(() => {
                this.$bvModal.show("error-message");
              }, 100);
            }
          }
          if (
            this.mode != "preview" &&
            this.hash_type != "versioned" &&
            this.page.entity_id
          ) {
            let vm = this;
            setTimeout(() => {
              try {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(() => {
                  this.showOrHideRecaptcha(this.$route);
                  // eslint-disable-next-line no-undef
                  grecaptcha
                    .execute(process.env.VUE_APP_RECAPTCHA, {
                      action: "submit"
                    })
                    .then(function (token) {
                      vm.axios
                        .post(vm.backendURL + hash + "/visited", {
                          token: token
                        })
                        .then(() => {})
                        .catch(() => {});
                    });
                });
              } catch (error) {
                console.log(
                  "SEND_VISITED_FLAG: CATCH_TRIGGERED. ERROR:",
                  error
                );
              }
            }, this.visitTimeout);
          }

          if (this.$route.name == "checkin") {
            setTimeout(() => {
              this.$bvModal.show("show-checkin");
            }, 100);
          }
          if (this.$route.name == "print") {
            try {
              setTimeout(() => {
                window.print();
              }, 100);
            } catch (e) {
              console.log(e);
            }
          }
          if (this.$route.name == "checkin-done") {
            this.$bvModal.show("show-checkin-thank-you");
          }

          if (this.$route.name == "checkout") {
            setTimeout(() => {
              this.$bvModal.show("show-checkout");
              this.hideCheckoutModals = true;
            }, 100);
          }

          if (
            "payments" in this.page.entity &&
            this.page.entity.payments.data.length > 0 &&
            this.selectedVariant.deposit_balance > 0 &&
            this.$route.query.action != "payment_confirmed" &&
            this.is("payment_available")
          ) {
            this.complete_payment = true;
            this.coupon.modal = true;
            this.coupon.page = "remaining";
            setTimeout(() => {
              this.$bvModal.show("coupon-box");
            }, 100);
          }
        })
        .catch((err) => {
          captureException(err);
          this.isLoading = false;
          if (
            err.response &&
            "status" in err.response &&
            (err.response.status > 400 || err.response.status < 500)
          ) {
            this.$bvModal.show("landingpage-removed");
          }
        })
        .then(() => {
          this.pageReady = true;
        });
      if (this.$route.hash == "#checkout") {
        this.$router.push({ name: "checkout" });
      }
    } else {
      this.isLoading = false;
      setTimeout(() => {
        this.$bvModal.show("landingpage-removed");
      }, 100);
      this.page.locale = "en";
      this.isLoading = false;
    }
    window.addEventListener("scroll", this.scroller);
  },
  methods: {
    recalculateDepositIfUpselling(arr, servAmount) {
      if (
        this.page?.upselling_enabled &&
        this.page?.entity_id &&
        arr.length &&
        !this.selectedVariant.deposit_type
      ) {
        const depositWithUpselling =
          (Number(this.selectedVariant.total_amount) + servAmount) *
          (this.selectedVariant.deposit_value / 100);
        return this.formatPrice( this.page.deposit_rounding ? Math.ceil(depositWithUpselling) : depositWithUpselling);
      } else {
        return (
          this.selectedVariant.total_amount &&
          this.selectedVariant.deposit_amount &&
          this.formatPrice(this.selectedVariant.deposit_amount)
        );
      }
    },
    getUpselling() {
      const serviceUrl =
        this.backendURL +
        this.hash +
        "/services?filter[variant_ref]=" +
        this.selected;
      this.axios
        .get(serviceUrl,{headers: {
            "Accept-Language": this.lpLocale
          }})
        .then((response) => {
          this.upselling = response.data.data;
        })
        .catch((err) => {
          captureException(err);
          this.upselling = [];
        });
    },
    showOrHideRecaptcha(to) {
      const el = document.getElementsByClassName("grecaptcha-badge");
      const recaptcha = el.length ? el[0] : null;
      if (recaptcha) {
        if (to.name === "checkout" || to.name === "checkin") {
          recaptcha.style.visibility = "visible";
        } else {
          recaptcha.style.visibility = "hidden";
        }
      }
    },
    hideModalAndShowBtn() {
      this.$bvModal.hide("show-price-table");
      setTimeout(() => {
        this.hideBtnBook = false;
      }, 1000);
    },
    renderData(responseData) {
      if (responseData.entity.data?.variants?.data?.length) {
        responseData.entity.data.variants.data =
          responseData.entity.data.variants.data.sort((a, b) =>
            a.rank > b.rank ? 1 : -1
          );
      }
      this.page.entity = responseData.entity.data;
      this.entity = this.page.entity;
      this.page.sections = responseData.sections;
      this.page.upselling_enabled = responseData.upselling_enabled;
      this.page.availability_request = responseData.availability_request;
      this.page.deposit_rounding = responseData.deposit_rounding ?? 0;
      this.page.currency = responseData.currency || "EUR";
      this.page.operationMode = responseData["operation_mode"];
      this.page.agb_url = responseData.agb_url;
      this.page.travel_insurance_company =
        responseData.travel_insurance_company;
      this.page.thank_you_background_img_url =
        ("thank_you_background_img_url" in responseData &&
          responseData.thank_you_background_img_url) ||
        "";
      this.page.privacy_url = responseData.privacy_url;
      this.page.map = responseData.map;
      this.page.checkin_settings = responseData.checkin;
      this.page.hash = responseData.hash;
      this.page.entity_id = responseData.entity_id;
      this.page.entity_type = responseData.entity_type;
      this.page.template_id = responseData.template_id;
      this.page.offer_show_rooms = responseData.offer_show_rooms;
      this.page.config = responseData.config;
      this.page.offer_show_discount = responseData.offer_show_discount;
      this.page.versions = responseData.versions;
      this.page.version_data = responseData.version_data || {};
      this.page.lp_show_versions = responseData.lp_show_versions;
      this.page.lp_show_chat = responseData.lp_show_chat;
      this.page.mobile_variants_side_by_side = responseData.mobile_variants_side_by_side;
      this.lpLocale =
        responseData.locale || responseData.entity?.data?.language || "de";
      this.page.locale = this.availableLocales.find(
        (el) => el === responseData.locale
      )
        ? responseData.locale
        : this.fallbackLocale;
      this.$i18n.locale = this.mainLocales.find(
        (el) => el === responseData.locale
      )
        ? responseData.locale
        : this.fallbackLocale;
      this.$moment.locale(this.page.locale);
      this.page.account = responseData.account.data;
      this.account = this.page.account;
      this.page.design_data = responseData.design_data.data;
      if (this.page.entity.order_customer) {
        this.request.salutation =
          this.$t(
            "form.title_options." + this.page.entity.order_customer.gender
          ) || "";
        this.request.firstname =
          this.getVal(this.page.entity.order_customer.firstname) || "";
        // Change Here the Fallbacks
        this.request.lang =
          this.page.entity.order_customer.lang ||
          this.page.entity.language ||
          this.lpLocale;
        this.request.gender = this.page.entity.order_customer.gender || "";
        this.request.lastname = this.page.entity.order_customer.lastname || "";
        this.request.email = this.page.entity.order_customer.email || "";
        this.request.phone = this.page.entity.order_customer.phone || "";
        this.request.street = this.page.entity.order_customer.street || "";
        this.request.zip = this.page.entity.order_customer.zip || "";
        this.request.city = this.page.entity.order_customer.city || "";
        this.request.country =
          this.page.entity.order_customer.country_alpha2 &&
          this.countries.find(
            (c) => c.code == this.page.entity.order_customer.country_alpha2
          )
            ? this.countries.find(
                (c) => c.code == this.page.entity.order_customer.country_alpha2
              ).value
            : "";
      }

      let vars = {};
      Object.keys(this.page.design_data.style).forEach((key) => {
        Object.keys(this.page.design_data.style[key]).forEach((prop) => {
          if (this.page.design_data.style[key][prop] != "") {
            vars["--" + key + "_" + prop] =
              this.page.design_data.style[key][prop];
          }
        });
      });
      if(this.page?.design_data?.config?.content?.text_position) {
        vars["--content_text_position"] = this.page.design_data.config.content.text_position;
      }
      this.reservation_process =
        this.designDataSettingsComputed().reservation_process || "twosteps";
      this.confirmation_config =
        this.designDataSettingsComputed().confirmation_config || "top";

      cssVars({ variables: vars });

      if (this.page.design_data.style_css != "") {
        var styleEl = document.createElement("style");
        styleEl.innerHTML = this.page.design_data.style_css.replace(
          /(<([^>]+)>)/gi,
          ""
        );
        document.head.appendChild(styleEl);
      }

      let hlIndex = 0;

      responseData.elements.data.sort(function (a, b) {
        if (a.priority < b.priority) return -1;
        if (a.priority > b.priority) return +1;
        return 0;
      });
      this.page.elements.content_raw = responseData.elements.data.filter(
        (item) =>
          item.section == "content" &&
          ["card", "block", "block.lp", "block.html"].includes(item.type)
      );
      this.page.elements.highlights_raw = responseData.elements.data.filter(
        (item) =>
          item.section === "highlights" && item.content_data.data.components
      );
      Object.keys(responseData.elements.data).forEach((element) => {
        let elData = responseData.elements.data[element];
        let type = elData.type == "block.lp" ? "block" : elData.type;
        if (!this.page.elements[elData.section]) {
          this.page.elements[elData.section] = {};
        }
        if (typeof this.page.elements[elData.section][type] != "object") {
          this.page.elements[elData.section][type] = [];
        }
        elData.key = hlIndex;
        if (elData.section == "highlights" && type == "block") {
          elData.key = hlIndex;
          if (elData.config && elData.config.expanded)
            this.highlightContentLoaded.push(hlIndex);
          hlIndex++;
        }
        if (
          (elData.section == "content" && type == "block") ||
          (elData.section == "content" && type == "card")
        ) {
          elData.key = hlIndex;
          if (elData.config && elData.config.expanded)
            this.blockContentLoaded.push(hlIndex);
          hlIndex++;
        }
        this.page.elements[elData.section][type].push(elData);
      });

      if (this.page.entity.variants.data.find((v) => v.confirmed)) {
        this.variantIsConfirmed = true;
      }

      if (
        this.page.design_data.config.navigation &&
        this.page.design_data.config.navigation.filter(
          (m) => m.type == "primary"
        ).length > 0
      ) {
        this.navigation.mainNav = [];
        this.page.design_data.config.navigation
          .filter((m) => m.type == "primary")
          .sort((a, b) => a.sequence - b.sequence)
          .forEach((menu) => {
            let translationURL =
              (this.page.locale in menu.translations &&
                menu.translations[this.page.locale] &&
                menu.translations[this.page.locale].url) ||
              "#";
            this.navigation.mainNav.push({
              url: translationURL,
              target: translationURL.indexOf("#") == 0 ? "_self" : "_blank",
              title: this.placeholder(menu.placeholder, menu.id)
            });
          });
        if (this.is("declined")) {
          this.navigation.mainNav.splice(0, 1);
        }
      } else {
        let defNavi = [
          {
            url: "#products",
            target: "_self",
            title:
              (this.debug ? "F:" : "") +
              this.placeholder("%label.menu.offer%", this.$t("page.nav.offer"))
          },
          {
            url: "#highlights",
            target: "_self",
            title:
              (this.debug ? "F:" : "") +
              this.placeholder(
                "%label.menu.highlights%",
                this.$t("page.nav.highlights")
              )
          },
          {
            url: "#footer",
            target: "_self",
            title:
              (this.debug ? "F:" : "") +
              this.placeholder(
                "%label.menu.footer%",
                this.$t("page.nav.info_services")
              )
          }
        ];
        this.navigation.mainNav = this.is("declined")
          ? defNavi.splice(0, 1)
          : defNavi;
      }
      this.changeVariant(0);
      return responseData;
    },
    selectVariant(variant, index) {
      this.selected = variant.ref;
      this.selectedPaymentMethod = null;
      let sortMedia = [
        "picture",
        "gallery",
        "picture_floorplan",
        "video",
        "panorama"
      ];
      variant.products.data.forEach((product) => {
        this.roomGalleryStart.push(false);
        product.media.forEach((media) => {
          media.sort = sortMedia.indexOf(media.scope);
          product.media.sort((a, b) => {
            return a.sort - b.sort;
          });
        });
      });
      this.selectedVariant = variant;
      this.selectedVariant.index = index;
      if (this.page?.upselling_enabled && this.page?.entity_id) {
        const preselectedServicesFiltered =
          variant.products?.data?.filter(({ type }) => type === "service") ||
          [];
        const groupByProductId = groupBy(
          preselectedServicesFiltered,
          "product_id"
        );
        this.preselectedServices = preselectedServicesFiltered.map(
          ({ product_id, origin }) => {
            const mapByQuantity = groupByProductId[product_id].map(
              ({ quantity }) => quantity
            );
            return {
              quantity: sum(mapByQuantity),
              id: product_id,
              origin: origin
            };
          }
        );
        this.getUpselling();
      }
    },
    changeVariant(step) {
      let variants = this.page.entity.variants.data;

      if (this.variantIsConfirmed) {
        const confirmedVariant = variants.find((v) => v.confirmed);
        this.selectVariant(confirmedVariant, step);
      } else {
        this.selectVariant(
          variants[this.selectedVariant.index + step],
          this.selectedVariant.index + step
        );
      }
    },
    closeThankYou() {
      this.$bvModal.hide("show-checkout-thank-you");
      window.location.href =
        process.env.VUE_APP_FRONTEND + "/" + this.page.hash;
    },
    visibilityChanged(isVisible) {
      this.selectedVariantVisible = isVisible;
    },
    visibilityChanged1(isVisible) {
      this.selectedVariantVisible1 = isVisible;
    },
    scroller() {
      let element = document.getElementById("mobile-menu");
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        element && element.classList.add("add-background");
      } else {
        element && element.classList.remove("add-background");
      }
    },
    getOfferMarkerStyle(style) {
      let cssStyle = {
        color: style["font-color"],
        backgroundColor: style["background-color"]
      };
      if (style["font-weight"] == "bold") cssStyle.fontWeight = "bold";
      if (style["font-style"] == "italic") cssStyle.fontStyle = "italic";
      return cssStyle;
    }
  }
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

#containerOrder {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

#containerOrder > #content {
  order: 1;
}

#containerOrder > #products {
  order: 4;
}

#containerOrder > #personal-info-component {
  order: 4;
}

#containerOrder > #highlights {
  order: 5;
}

#containerOrder > #receptionistBottom {
  order: 6;
}

#containerOrder > #footerContainer {
  order: 7;
}

.grecaptcha-badge {
  visibility: hidden;
  display: block !important;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  right: 4px !important;
  z-index: 9000;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter,
.slide-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.grecaptcha-checkout-container {
  width: 256px;
  height: 60px;
  display: block;
  transition: right 0.3s ease 0s;
  position: fixed;
  bottom: 14px;
  right: -186px;
  box-shadow: gray 0px 0px 5px;
  border-radius: 2px;
  overflow: hidden;
}

.g-recaptcha-textarea {
  width: 250px;
  height: 40px;
  border: 1px solid rgb(193, 193, 193);
  margin: 10px 25px;
  padding: 0px;
  resize: none;
  display: none;
}

.modal-cancel-p-header {
  border-bottom: 0 !important;
  padding-bottom: 0.7rem !important;
}

.modal-cancel-p-body {
  padding-top: 0 !important;
}

.variant-desc {
  word-break: break-word;
}

#show-checkout .modal-dialog {
  padding: 0;
}

#show-checkout #show-checkout___BV_modal_body_ {
  height: 100%;
}

#show-checkout-thank-you .modal-dialog,
#show-checkin-thank-you .modal-dialog {
  padding: 0;
}

button.action-book:active {
  font-size: 17px !important;
}

#show-price-table .modal-dialog .modal-content {
  border-radius: 5px !important;
}

#show-price-table .modal-dialog .modal-content .modal-footer {
  display: block !important;
}

@media (min-width: 789px) {
  .grecaptcha-badge {
    left: 4px !important;
  }
}

@media (max-width: 1024px) {
  .modal-dialog {
    top: 0 !important;
  }
}
</style>