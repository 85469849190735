import { useRoute, useRouter } from "vue-router/composables";
import { countryList } from "@/models/countryList";
import { getCurrentInstance, reactive } from "vue";
import { currenciesList } from "@/models/currenciesList";

const showModalOffer = reactive({ value: null })

export function useCheckoutData() {
  const { proxy } = getCurrentInstance();
  const router = useRouter();
  const route = useRoute();
  const placeholder = (
    placeholder,
    fallback = false,
    forceScope = false,
    params = {}
  ) => proxy._provided.placeholder(placeholder, fallback, forceScope, params);

  const hash =
    route.name == "version_hashed"
      ? route.params.version_hash
      : route.params.hash || null;
  const countries = countryList
    .map((country) => ({
      value: country.alpha3,
      code: country.code,
      pinned: country.pinned,
      text:
        typeof country.translations.data[proxy.$i18n.locale] != "undefined"
          ? country.translations.data[proxy.$i18n.locale].name
          : country.translations.data.en.name
    }))
    .sort(function (a, b) {
      if (a.pinned < b.pinned) return +1;
      if (a.pinned > b.pinned) return -1;
      if (a.text > b.text) return +1;
      if (a.text < b.text) return -1;
      return 0;
    })
    .map((country) => ({
      value: country.value,
      code: country.code,
      pinned: country.pinned,
      text: country.text
    }));

  const page = proxy._provided.page;
  const request = proxy._provided.request;
  const selectedVariant = proxy._provided.selectedVariant;
  const reservation_process = proxy._provided.reservation_process;
  const showDepositAmountComputed = proxy._provided.showDepositAmountComputed;
  const lpLocale = proxy._provided.lpLocale;

  const currency = {
    defaultCurrency: "EUR",
    availableCurrencies: currenciesList
  };

  const datetimeFormats = [
    {
      lang: "de",
      longForm: "dddd, Do MMMM YYYY"
    },
    {
      lang: "en",
      longForm: "dddd, Do MMMM YYYY"
    },
    {
      lang: "fr",
      longForm: "dddd, Do MMMM YYYY"
    },
    {
      lang: "it",
      longForm: "dddd, DD MMMM YYYY"
    }
  ];

  const scopes = {
    expired: [
      "crm.campaign.order.unconfirmed",
      "crm.campaign.offer.unconfirmed"
    ],
    declined: ["crm.campaign.request.declined"],
    payment_available: [
      "crm.campaign.order.unconfirmed",
      "crm.campaign.offer.unconfirmed"
    ],
    option: [
      "crm.campaign.order.unconfirmed",
      "crm.campaign.order.confirmed_byguest",
      "crm.campaign.order.paid_byguest"
    ],
    offer: [
      "crm.campaign.request",
      "crm.campaign.request.declined",
      "crm.campaign.request.waitinglist",
      "crm.campaign.offer.unconfirmed",
      "crm.campaign.offer.confirmed_byguest",
      "crm.campaign.offer.reminder",
      "crm.campaign.offer.followup",
      "crm.campaign.offer.expiring",
      "crm.campaign.offer.paid_byguest"
    ],
    order: [
      "crm.campaign.order.confirmed",
      "crm.campaign.order.pre_experience",
      "crm.campaign.order.post_experience"
    ],
    confirmed: ["crm.campaign.order.confirmed"],
    spa_confirmed: [
      "crm.campaign.order.confirmed",
      "crm.campaign.order.cancellation"
    ],
    confirmed_byguest: [
      "crm.campaign.order.confirmed_byguest",
      "crm.campaign.offer.confirmed_byguest",
      "crm.campaign.offer.paid_byguest",
      "crm.campaign.order.paid_byguest"
    ],
    checkin: ["crm.campaign.order.confirmed"],
    disabled_button: [
      "crm.campaign.offer.confirmed_byguest",
      "crm.campaign.order.confirmed_byguest",
      "crm.campaign.order.confirmed",
      "crm.campaign.request",
      "crm.campaign.request.declined",
      "crm.campaign.request.waitinglist",
      "crm.campaign.order.cancellation",
      "crm.campaign.order.pre_experience",
      "crm.campaign.order.post_experience",
      "crm.campaign.offer.paid_byguest",
      "crm.campaign.order.paid_byguest"
    ],
    disabled_cta_bar: [
      "crm.campaign.request",
      "crm.campaign.request.declined",
      "crm.campaign.request.waitinglist",
      "crm.campaign.offer.confirmed_byguest",
      "crm.campaign.order.confirmed_byguest",
      "crm.campaign.order.confirmed",
      "crm.campaign.order.cancellation",
      "crm.campaign.order.pre_experience",
      "crm.campaign.order.post_experience"
    ]
  };

  const env = proxy.$route.query.env || false;

  function closeCheckOut() {
    proxy.$bvModal.hide("show-checkout");
    router.push({
      name: "hashed",
      params: {
        hash: hash
      }
    });
  }

  function getVal(value, fallback = false) {
    return typeof value !== "undefined" || value !== null ? value : fallback;
  }

  function validateEmail(email) {
    let pattern = /^\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(?:\.\w{2,63})+$/g;
    return pattern.test(email);
  }

  function formatPrice(price, currency = null) {
    if (!currency) {
      currency = currentCurrency().code;
    }
    return Intl
      ? Intl.NumberFormat(currentCurrency().locale, {
          style: "currency",
          currency
        }).format(price)
      : parseFloat(price).toFixed(2) +
          " x" +
          (currency.availableCurrencies.find((c) => c.code === currency)
            ?.symbol || "");
  }

  function currentCurrency() {
    let currencyDef = page.currency || "EUR";
    return currency.availableCurrencies.find(
      (currency) => currency.code === currencyDef
    );
  }

  function formatDate(date, timezone = "Europe/Rome") {
    const datetimeFormat = datetimeFormats.find(
      (ft) => ft.lang == (proxy.$i18n.locale || "en")
    ).longForm;
    if (date) {
      return proxy.$moment(new Date(date)).tz(timezone).format(datetimeFormat);
    }
  }

  function is(type, scope = false) {
    return scopes[type]
      ? scopes[type].indexOf(scope ? scope : page.entity?.scope) >= 0
        ? true
        : false
      : false;
  }

  function backendURL() {
    return (
      (env === "prod"
        ? process.env.VUE_APP_API_BACKEND_PROD
        : process.env.VUE_APP_API_BACKEND) + "/pub/lp/"
    );
  }

  function timeDifference(start, end) {
    if (!start || !end) {
      return;
    }
    start = start.split(":");
    end = end.split(":");
    let startDate = new Date(0, 0, 0, start[0], start[1], 0);
    let endDate = new Date(0, 0, 0, end[0], end[1], 0);
    let diff = endDate.getTime() - startDate.getTime();
    let hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    let minutes = Math.floor(diff / 1000 / 60);

    return hours * 60 + minutes;
  }

  function formatPriceTitle(text) {
    text = text || "";
    let text_replaced = text.replace(/^\s+/, "");
    return (
      '<div style="padding-left:' +
      ((text.length - text_replaced.length) * 4).toString() +
      'px">' +
      text_replaced +
      "</div>"
    );
  }

  function getCountry(val) {
    return countries.find((c) => c.value === val)?.text;
  }

  return {
    closeCheckOut,
    placeholder,
    getVal,
    getCountry,
    validateEmail,
    countries,
    formatPrice,
    formatDate,
    is,
    backendURL,
    timeDifference,
    formatPriceTitle,
    page,
    request,
    selectedVariant,
    reservation_process,
    showModalOffer,
    showDepositAmountComputed,
    lpLocale,
    scopes
  };
}