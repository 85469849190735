import { render, staticRenderFns } from "./PriceTableServices.vue?vue&type=template&id=c8c2b9c0&scoped=true"
import script from "./PriceTableServices.vue?vue&type=script&setup=true&lang=js"
export * from "./PriceTableServices.vue?vue&type=script&setup=true&lang=js"
import style0 from "./PriceTableServices.vue?vue&type=style&index=0&id=c8c2b9c0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8c2b9c0",
  null
  
)

export default component.exports