var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.fixedFooterVisible)?_c('div',{staticClass:"fixed-footer"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"px-2 px-md-1 d-flex justify-content-between",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticClass:"variant-name"},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.selectedVariant.title
                  ? _vm.selectedVariant.title
                  : _vm.placeholder('%label.offer.title%', _vm.$t('entity.offer')) +
                    ': ' +
                    _vm.page.entity.slug
              )}})]),_c('div',{staticClass:"variant-extras d-flex flex-column align-items-start"},[(
                _vm.$moment(new Date()).diff(
                  _vm.$moment(
                    _vm.selectedVariant.cancelpolicy_data.refundable_until
                  ).format()
                ) < 0
              )?[_c('div',[(
                    'content' in _vm.page.design_data.config &&
                    'badge_cancellation' in _vm.page.design_data.config.content &&
                    _vm.page.design_data.config.content.badge_cancellation
                  )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"badge badge-success cancel_policy-badge mb-1",attrs:{"title":_vm.placeholder(
                      '%label.cancel_policy.free_cancellation_until%',
                      _vm.$t('entity.cancel_policy.free_cancellation_until')
                    ) +
                    ' ' +
                    _vm.formatDate(
                      _vm.selectedVariant.cancelpolicy_data.refundable_until
                    )}},[_c('span',{domProps:{"innerHTML":_vm._s(
                      _vm.placeholder(
                        '%label.cancel_policy.free_cancellation%',
                        _vm.$t('entity.cancel_policy.free_cancellation')
                      )
                    )}})]):_vm._e()])]:_vm._e(),(_vm.selectedVariant.deposit_amount == 0)?[_c('div',[(
                    'content' in _vm.page.design_data.config &&
                    'badge_deposit' in _vm.page.design_data.config.content &&
                    _vm.page.design_data.config.content.badge_deposit
                  )?_c('span',{staticClass:"badge badge-secondary no_deposit-badge",domProps:{"innerHTML":_vm._s(
                    _vm.placeholder(
                      '%label.deposit_policy.no_deposit_needed%',
                      _vm.$t('entity.no_deposit_needed')
                    )
                  )}}):_vm._e()])]:_vm._e()],2)]),_c('div',{staticClass:"d-flex footer-right-col"},[_c('div',{staticClass:"variant-price-show-table text-center text-lg-left d-flex align-items-end d-none d-lg-flex ml-2 mr-2 ml-lg-0 mr-lg-4"},[_c('a',{class:{
                'custom-italic':
                  'rooms' in _vm.page.design_data.style &&
                  'storno_italic' in _vm.page.design_data.style.rooms &&
                  _vm.page.design_data.style.rooms.storno_italic,
                'custom-bold':
                  'rooms' in _vm.page.design_data.style &&
                  'storno_bold' in _vm.page.design_data.style.rooms &&
                  _vm.page.design_data.style.rooms.storno_bold
              },on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.show('show-price-table')}}},[_vm._v(" "+_vm._s(_vm.$t("entity.price.show_price_details_short"))+" ")])]),_c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('div',{staticClass:"variant-price-label"},[_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.placeholder(
                    '%label.amount%',
                    _vm.$t('entity.price.total_price')
                  )
                )}}),_vm._v(": ")]),_c('div',{staticClass:"variant-price-value"},[_vm._v(" "+_vm._s(_vm.formatPrice(Number(_vm.selectedVariant.total_amount) + _setup.calcTotalAmountOfServices()))+" ")])]),(_vm.showButton)?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center variant-price-action"},[_c(_setup.BookButton,{attrs:{"page":_vm.page,"btn-disabled":_vm.btnDisabled,"selectedVariantRef":_vm.selectedVariant.ref,"lpUrl":_vm.lpUrl}})],1):_vm._e()])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }